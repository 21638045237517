import React, { useEffect, useState } from 'react';
import { Box, Modal, Typography, IconButton, TextField, Button, Stack, Chip, InputAdornment, ClickAwayListener, Paper, MenuList, MenuItem, Snackbar, Alert, Autocomplete } from '@mui/material';
import TypographyWithI18N from 'pages/components/TypographyWithI18N';
import { colors } from 'styles/colors';
import { CloseRoundedWhiteIcon, CloseRoundIcon, SearchIcon } from 'assets';
import { IMessageRequest, ISendMessageRequest } from './chatPopup';
import { useAppSelector } from 'store/hook';
import { RootState } from 'store';
import { axiosPostGroupSearch, sendMessage } from 'services/api';
import { ApiPaths_Groups } from 'models/apiPaths';
import { getUrl } from 'utilities/userImageURL';
import AvatarImg from 'pages/components/avatarImg';

interface CreateMessagePopupProps {
  open: boolean;
  onClose: () => void;
  onMessageSent?: (conversationId: string) => void;
}

const CreateMessagePopup: React.FC<CreateMessagePopupProps> = ({ open, onClose, onMessageSent }) => {
  const [selectedFriends, setSelectedFriends] = useState<any[]>([]);
  const [userSearchQuery, setUserSearchQuery] = useState('');
  const [dialogOpen, setDialogOpen] = useState(false);
  const [userSearchList, setUserSearchList] = useState<any[]>([]);
  const [showSearchList, setShowSearchList] = useState<boolean>();
  const [newMessage, setNewMessage] = useState("");
  const MAX_CHAR_LIMIT = 1900;
  const { userProfile }: any = useAppSelector(
    (state: RootState) => state.userAuth
  );

  const handleClose = (event: React.SyntheticEvent, reason?: string) => {
    if (reason === "backdropClick") return;
    onClose();
  };

  const handleUserSelection = (user: any) => {
    setSelectedFriends((prevSelected) => {
      const userExists = prevSelected.some((selectedUser) => selectedUser.id === user.id);

      if (userExists) {
        return prevSelected.filter((selectedUser) => selectedUser.id !== user.id);
      } else {
        if (prevSelected.length >= 6) {
          setDialogOpen(true);
          return prevSelected;
        }
        return [...prevSelected, user];
      }
    });
  };

  const handleMessageUpdate = (input: string) => {
    let message = newMessage;
    if (input.length <= MAX_CHAR_LIMIT) {
      message = input;
    } else {
      message = input.slice(0, MAX_CHAR_LIMIT);
    }
    setNewMessage(message);
  };

  const handleSend = () => {
    const inputMessage: IMessageRequest = {
      content: newMessage,
      delivered: [],
      read: [],
      sent: true,
      participantIds: selectedFriends.map(friend => friend.id),
      senderId: userProfile.id,
      createdAt: new Date()
    }

    const messageRequest: ISendMessageRequest = {
      conversationId: null,
      message: inputMessage
    }

    sendMessage(messageRequest).then((res) => {
      if (onMessageSent) {
        onMessageSent(res.conversationId);
      }
    }).catch((err) => {
      console.error("Error Sending Message:", err);
    })
      .finally(() => {
        setNewMessage('');
        setSelectedFriends([]);
        onClose();
      });
  };

  const handleRemoveParticipant = (id: string) => {
    setSelectedFriends((prevFriends) =>
      prevFriends.filter((friend) => friend.id !== id)
    );
  };

  const getSelectedMembersChips = () => {
    return <Stack direction="row" spacing={1}>
      {selectedFriends.map((friend, index) => {
        return <Chip key={index} color='primary' label={friend.name.split(' ')[0]} onDelete={() => handleRemoveParticipant(friend.id)} />
      })}
    </Stack>
  }

  const handleUserSearch = async (text: string) => {
    const payload = {
      keyword: text,
      enforceGroupMembership: true,
      enforceFriendship: true
    };
    try {
      const groupSearchResponse = await axiosPostGroupSearch(ApiPaths_Groups.GROUP_USER_SEARCH, payload);
      setUserSearchList(groupSearchResponse
        .filter((_itm: any) => _itm.user)
        .map((_itm: any) => {
          const userActive = Boolean(_itm.user);
          return {
            userActive: userActive,
            id: _itm.user?.id,
            name: _itm.user?.username,
            imageId: _itm.user?.userImage?.id,
            imageUrl: _itm.user?.userImage?.id ? getUrl(_itm.user?.userImage?.id) : '',
          };
        }));
      setShowSearchList(true);
    } catch (error) {
      console.log(error);
      setShowSearchList(false);
    }
  };

  const resetUserSearch = () => {
    setUserSearchQuery('');
    setUserSearchList([]);
    setShowSearchList(false);
  };

  useEffect(() => {
    setSelectedFriends([]);
  }, [])

  return (
    <Modal open={open} onClose={handleClose} disableEscapeKeyDown>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: '45%',
          height: '65%',
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 2,
          borderRadius: 2,
          overflow: 'auto'
        }}
      >
        <Box sx={{
          display: "flex",
          justifyContent: 'space-between',
        }}>
          <Box sx={{
            display: "flex",
            justifyContent: 'flex-start',
            alignItems: 'center'
          }}>
            <Typography variant="h6">Create Message</Typography>
          </Box>
          <Box>
            <IconButton onClick={onClose} sx={{ padding: 0 }}>
              <CloseRoundIcon />
            </IconButton>
          </Box>
        </Box>
        <Box sx={{ display: "flex", alignItems: "center", backgroundColor: '#f6f6f6', marginTop: 2 }}>
          <Typography className='bodyTextBold' sx={{ color: 'GrayText', marginLeft: 1 }}>To:</Typography>
          <Autocomplete
            clearIcon={false}
            multiple
            value={selectedFriends}
            options={userSearchList}
            getOptionLabel={(option) => option.name || ""}
            open={userSearchQuery.length > 0}
            onInputChange={(event, newInputValue) => {
              setUserSearchQuery(newInputValue);
              if (newInputValue !== "") {
                handleUserSearch(newInputValue);
              } else {
                resetUserSearch();
              }
            }}
            onChange={(event, newValue) => {
              const addedUsers = newValue.filter(
                (user) => !selectedFriends.some((selected) => selected.id === user.id)
              );
              const removedUsers = selectedFriends.filter(
                (selected) => !newValue.some((user) => user.id === selected.id)
              );
              addedUsers.forEach(handleUserSelection);
              removedUsers.forEach(handleUserSelection);
            }}
            renderTags={(value, getTagProps) =>
              value.map((option, index) => (
                <Chip
                  key={index}
                  sx={{ marginRight: 1, paddingLeft: option?.imageId ? 0 : '5px', backgroundColor: '#04A2A2' }}
                  color='primary'
                  className="bodyTextSmall"
                  avatar={
                    <AvatarImg
                      id="userImg"
                      enableLetterImg={option?.imageId ? false : true}
                      alt={option?.name}
                      src={option?.imageId ? option.imageUrl : ""}
                      width={25}
                      height={25}
                      name={option?.name}
                      nameStyle={{ fontSize: 12 }}
                      style={{ borderRadius: "50%" }}
                    />}
                  label={option.name.split(' ')[0]}
                  onDelete={() => handleRemoveParticipant(option.id)}
                  deleteIcon={<CloseRoundedWhiteIcon />}
                />
              ))
            }
            renderOption={(props, option) => (
              <MenuList sx={{ maxHeight: 100, px: 1.5 }}>
                <MenuItem
                  {...props}
                  key={option.id || props.key}
                  sx={{ padding: 1.5 }}
                >
                  <AvatarImg
                    id="userImg"
                    enableLetterImg={option?.imageId ? false : true}
                    alt={option?.name}
                    src={option?.imageId ? option.imageUrl : ""}
                    width={32}
                    height={32}
                    name={option?.name}
                    nameStyle={{ fontSize: 12 }}
                    style={{ objectFit: "cover", borderRadius: "50%" }}
                  />
                  <TypographyWithI18N
                    className="bodyText"
                    i18nKey={option.name}
                    ml={1}
                    color={colors.text.approxNero}
                    sx={{ maxWidth: "85%", overflow: "hidden", textOverflow: "ellipsis" }}
                  />
                </MenuItem>
              </MenuList>
            )}
            renderInput={(params) => (
              <TextField
                autoComplete="off"
                {...params}
                InputProps={{
                  ...params.InputProps,
                  sx: {
                    height: "auto",
                    minHeight: 40,
                    pr: 0,
                    borderRadius: "30px",
                    "& fieldset": { border: "none" },
                  },
                  endAdornment: null,
                }}
              />
            )}
            sx={{ flex: 1 }}
          />
        </Box>
        <Box mt={2} sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
          <TextField
            fullWidth
            placeholder="Write your message..."
            minRows={6}
            maxRows={15}
            variant="outlined"
            multiline
            value={newMessage}
            error={newMessage.length === 1900}
            onChange={(e) => {
              handleMessageUpdate(e.target.value);
            }}
            sx={{
              flexGrow: 1,
              backgroundColor: '#f6f6f6',
              '& .MuiOutlinedInput-notchedOutline': {
                border: 'none',
              },
            }}
          />
        </Box>
        <Box display='flex' flexDirection='row' justifyContent='space-between'>
          <Typography sx={{color: colors.text.approxSuvaGrey}}>{newMessage.length} / 1900</Typography>
          {newMessage.length === 1900 && <TypographyWithI18N
            className='bodyTextSmall'
            i18nKey={"Input Limit Reached"}
            ml={1}
            color='red'
            sx={{
              maxWidth: '85%',
              overflow: 'hidden',
              textOverflow: 'ellipsis'
            }}
          />}
        </Box>
        <Box mt={2} display="flex" justifyContent="center">
          <Button variant="contained" disabled={selectedFriends.length === 0 || newMessage.trimEnd() === ''} color="primary" sx={{ mr: 2, minWidth: 100 }} onClick={handleSend}>
            Send
          </Button>
          <Button variant="outlined" onClick={onClose} sx={{ minWidth: 100 }}>
            Cancel
          </Button>
        </Box>
        {dialogOpen &&
          <Snackbar open={dialogOpen} anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }} autoHideDuration={2000} onClose={() => setDialogOpen(false)}>
            <Alert
              onClose={() => setDialogOpen(false)}
              severity="error"
              variant="filled"
              sx={{ width: '60%' }}
            >
              You already added 7 members to the conversation. Adding more members is not allowed.
            </Alert>
          </Snackbar>
        }
      </Box>
    </Modal>
  );
};

export default CreateMessagePopup;