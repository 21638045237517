import { useEffect, useState } from 'react';
import {
  AvatarGroup,
  Badge,
  Box,
  ClickAwayListener,
  IconButton,
  InputAdornment,
  MenuItem,
  MenuList,
  Paper,
  TextField,
  Typography
} from '@mui/material';
import { CloseRoundIcon, SearchIcon } from 'assets';
import { colors } from 'styles/colors';
import TypographyWithI18N from 'pages/components/TypographyWithI18N';
import { useLocation, useNavigate } from 'react-router-dom';
import { BackArrowIcon, CreateMessage } from 'assets/icon';
import { RoutesType, RoutesTypeTitle } from 'models/enum';
import AvatarImg from 'pages/components/avatarImg';
import { getUrl, groupImgUrl } from 'utilities/userImageURL';
import {
  axiosGetGroupPost,
  axiosPostGroupSearch,
  createConversation,
  createGroupConversation,
  fetchUserDetails,
  searchByMessage
} from 'services/api';
import { ApiPaths, ApiPaths_Groups, IUserDetails, IGuestModeOptions } from 'models/apiPaths';
import { IUserGroupSearch } from 'models/groups';
import { scrollToPageTop } from 'utilities/helpers';
import CreateMessagePopup from 'pages/modules/chats/createMessagePopup';
import SigninModal from 'pages/components/signinModal';
import { useAppSelector } from 'store/hook';
import { RootState } from 'store';

const Header = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [userGroupQuery, setUserGroupQuery] = useState('');
  const [showGroupList, setShowGroupList] = useState<boolean>();
  const [userGroupList, setUserGroupList] = useState<any[]>([]);
  const [userList, setUserList] = useState<any[]>([]);
  const [groupList, setGroupList] = useState<any[]>([]);
  const [messageList, setMessageList] = useState<any[]>([]);
  const pathnames = location.pathname.split('/').filter((x) => x);
  const postUserName = location.state?.headerName;
  const [popupOpen, setPopupOpen] = useState(false);
  const [openSigninDialog, setOpenSigninDialog] = useState(false);
  const [loggedUser, setLoggedUser] = useState<IUserDetails>();
  const { isChatEnabled }: any = useAppSelector(
    (state: RootState) => state.featureFlag
  );

  const { isLoggedIn }: any = useAppSelector(
    (state: RootState) => state.userAuth
  );

  const [guestModeOptions, setGuestModeOptions] = useState<IGuestModeOptions>({
    guestMode: false,
    navigateTo: null,
    menu: null,
    redirectTo: null,
    action: null,
    actionItem: null
  });

  const { groupNameData, profileNameData }: any = useAppSelector(
    (state: RootState) => state.utilsData
  );

  useEffect(() => {
    scrollToPageTop();
  }, [location]);

  const getTitle = (title: string) => {
    switch (title) {
      case RoutesType.home:
        return RoutesTypeTitle.home;
      case RoutesType.createGroup:
        return RoutesTypeTitle.createGroup;
      case RoutesType.createPost:
        return RoutesTypeTitle.createPost;
      case RoutesType.createPoll:
          return RoutesTypeTitle.createPoll;
      case isChatEnabled && RoutesType.chat:
        return RoutesTypeTitle.chat;
      case RoutesType.groupPost:
        return groupNameData ? groupNameData : RoutesTypeTitle.groupPost;
      case RoutesType.groups:
        return RoutesTypeTitle.groups;
      case RoutesType.profilePage:
        return RoutesTypeTitle.profilePage;
      case RoutesType.viewPost:
        return postUserName ? postUserName : profileNameData ?? RoutesTypeTitle.viewPost;
      case RoutesType.notification:
        return RoutesTypeTitle.notification;
      case RoutesType.friendList:
        return RoutesTypeTitle.friendlist;
      case RoutesType.groupList:
        return RoutesTypeTitle.grouplist;
      default:
        return RoutesTypeTitle.home;
    }
  };

  const userGroupItems = (items: IUserGroupSearch[]) => {
    return items.map((_itm) => {
      const userActive = Boolean(_itm.user);
      return {
        userActive: userActive,
        id: userActive ? _itm?.user?.id : _itm?.group?.id,
        name: userActive ? _itm?.user?.username : _itm?.group?.name,
        imageId: userActive
          ? _itm?.user?.userImage?.id
          : _itm?.group?.groupImageDto?.id,
        imageUrl: userActive
          ? _itm?.user?.userImage?.id
            ? getUrl(_itm?.user?.userImage?.id)
            : ''
          : _itm?.group?.groupImageDto?.id
            ? groupImgUrl(_itm?.group?.groupImageDto?.id)
            : ''
      };
    });
  };

  const handleSearchResult = (
    items: IUserGroupSearch[],
    messageSearchResponse: any
  ) => {
    setUserList(
      items
        .filter((_itm) => _itm.user)
        .map((_itm) => {
          const userActive = Boolean(_itm.user);
          return {
            userActive: userActive,
            id: _itm.user?.id,
            name: _itm.user?.username,
            imageId: _itm.user?.userImage?.id,
            imageUrl: _itm.user?.userImage?.id
              ? getUrl(_itm.user?.userImage?.id)
              : ''
          };
        })
    );
    setGroupList(
      items
        .filter((_itm) => _itm.group)
        .map((_itm) => {
          return {
            id: _itm?.group?.id,
            name: _itm?.group?.name,
            imageId: _itm?.group?.groupImageDto?.id,
            imageUrl: _itm?.group?.groupImageDto?.id
              ? groupImgUrl(_itm?.group?.groupImageDto?.id)
              : ''
          };
        })
    );
    setMessageList(messageSearchResponse);
  };

  const handleUserGroupSearch = async (text: string) => {
    const payload = {
      keyword: text,
      enforceGroupMembership: false,
      enforceFriendship: false
    };
    try {
      if (pathnames[0] !== 'chat') {

        const response = await axiosPostGroupSearch(
          ApiPaths_Groups.GROUP_USER_SEARCH,
          payload
        );

        response && setUserGroupList(userGroupItems(response));

        setShowGroupList(true);
      }
      else {
        const [groupSearchResponse, messageSearchResponse] = await Promise.all([
          axiosPostGroupSearch(ApiPaths_Groups.GROUP_USER_SEARCH, payload),
          searchByMessage(text)
        ]);

        handleSearchResult(groupSearchResponse, messageSearchResponse);

        setShowGroupList(true);
      }
    } catch (error) {
      console.log(error);
      setShowGroupList(false);
    }
  };

  const resetUserGroup = () => {
    setUserGroupList([]);
    setUserGroupQuery('');
    setShowGroupList(false);
    setUserList([]);
    setGroupList([]);
    setMessageList([]);
  };

  const userProfileDetails = async () => {
    const userProfile = await fetchUserDetails(ApiPaths.ME);
    setLoggedUser(userProfile);
  };

  const handleSearchResultClick = async (loggedUserId: any, _itm: any) => {
    if (_itm.userActive) {
      // User Selection
      const apiPath = `${ApiPaths.GET_USER}/${_itm.id}`;
      const userDetail = await fetchUserDetails(apiPath);
      if (userDetail.friend) {
        const newConversation = await createConversation([
          loggedUserId,
          _itm.id
        ]);
        navigate(`/chat/${newConversation.conversation.id}`);
      } else {
        navigate(`/${'profile'}/${_itm?.id}`);
      }
    } else {
      // Group Selection
      const group = await axiosGetGroupPost(
        _itm.id,
        ApiPaths_Groups.GROUP_POST
      );
      if (group.member) {
        const newGroupConversation = await createGroupConversation(_itm.id);
        navigate(`/chat/groups/${newGroupConversation.id}`);
      } else {
        navigate(`/${'group'}/${_itm?.id}`);
      }
    }
  };

  const getDisplayName = (friends: any[]) => {
    if (friends.length === 1) return friends[0].username;
    if (friends.length === 2)
      return `${friends[0].username}, ${friends[1].username}`;
    if (friends.length > 2) {
      return `${friends[0].username}, ${friends[1].username}, +${friends.length - 2}`;
    }
    return '';
  };

  const formatTimeDifference = (message: any): string => {
    const updatedDate = new Date(message.updatedAt);
    const currentDate = new Date();

    const isToday = updatedDate.toDateString() === currentDate.toDateString();

    const yesterday = new Date();
    yesterday.setDate(currentDate.getDate() - 1);
    const isYesterday = updatedDate.toDateString() === yesterday.toDateString();

    if (isToday) {
      return updatedDate.toLocaleTimeString(undefined, {
        hour: '2-digit',
        minute: '2-digit',
        hour12: true
      });
    } else if (isYesterday) {
      return 'Yesterday';
    } else {
      return updatedDate.toLocaleDateString(undefined, {
        day: '2-digit',
        month: '2-digit'
      });
    }
  };

  const getLastMessage = (conversation: any) => {
    if (
      conversation.message.senderId != null &&
      conversation.message.content != null
    ) {
      if (conversation.message.senderId === loggedUser?.id) {
        return 'You: ' + conversation.message.content;
      } else {
        const sender = conversation.participants.find(
          (x: any) => x.userId === conversation.message.senderId
        );
        const firstName = sender.username.split(' ')[0];
        return `${firstName}: ` + conversation.message.content;
      }
    }
    return '';
  };

  const renderAvatar = (friend: any, index: number) => (
    <AvatarImg
      key={index}
      id='userImg'
      enableLetterImg={!friend.imageId}
      alt={friend.username}
      src={getUrl(friend.imageId)}
      name={friend.username}
      width={32}
      height={32}
      nameStyle={{
        fontSize: 12
      }}
      style={{
        objectFit: 'cover',
        borderRadius: '50%',
        border: `2px solid ${colors.border.primary}`,
        marginLeft: index > 0 ? -30 : 0
      }}
    />
  );

  const getGroupImage = (groupInfo: any) => {
    return (
      <AvatarImg
        id='userImg'
        enableLetterImg={groupInfo?.groupImageId ? false : true}
        alt={groupInfo.groupName}
        src={
          groupInfo?.groupImageId ? groupImgUrl(groupInfo?.groupImageId) : ''
        }
        name={groupInfo.groupName}
        nameStyle={{
          fontSize: 12
        }}
        width={32}
        height={32}
        style={{
          objectFit: 'cover',
          borderRadius: '50%'
        }}
      />
    );
  };

  const getAvatars = (friends: any[]) => {
    if (friends.length === 1) {
      return friends.map((friend, index) => (
        <Badge
          key={index}
          sx={{
            '& .MuiBadge-dot': {
              borderRadius: '50%',
              borderColor: 'white',
              width: friend.onlineStatus ? '15px' : 0,
              height: friend.onlineStatus ? '15px' : 0,
              border: friend.onlineStatus
                ? `2px solid ${colors.border.primary}`
                : '',
              backgroundColor: '#4caf50'
            }
          }}
          variant='dot'
          overlap='circular'
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        >
          {renderAvatar(friend, index)}
        </Badge>
      ));
    } else {
      return (
        <AvatarGroup
          max={2}
          spacing={30}
          sx={{
            '& .MuiAvatarGroup-avatar': {
              width: 32,
              height: 32,
              fontSize: 16,
              backgroundColor: colors.text.primaryTheme
            }
          }}
        >
          {friends.map((friend, index) => renderAvatar(friend, index))}
        </AvatarGroup>
      );
    }
  };

  const HighlightedMessage = (
    conversation: any,
    keyword: any,
    snippetLength = 30
  ) => {
    let message = conversation.message.content;
    const keywordIndex = message.toLowerCase().indexOf(keyword.toLowerCase());

    if (keywordIndex === -1) {
      return (
        <Typography
          fontSize={12}
          sx={{
            color: 'gray',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            width: 150
          }}
        >
          {message}
        </Typography>
      );
    }

    const start = Math.max(0, keywordIndex - snippetLength / 2);
    const end = Math.min(message.length, keywordIndex + snippetLength / 2);
    const snippet = message.substring(start, end);

    const parts = snippet.split(new RegExp(`(${keyword})`, 'gi'));

    let senderName;

    if (
      conversation.message.senderId != null &&
      conversation.message.content != null
    ) {
      if (conversation.message.senderId === loggedUser?.id) {
        senderName = 'You: ';
      } else {
        const sender = conversation.participants.find(
          (x: any) => x.userId === conversation.message.senderId
        );
        const firstName = sender.username.split(' ')[0];
        senderName = `${firstName}: `;
      }
    }

    return (
      <Typography
        fontSize={12}
        sx={{
          color: 'gray',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          width: 200
        }}
      >
        {senderName}
        {start > 0 && '..'}
        {parts.map((part: any, index: any) =>
          part.toLowerCase() === keyword.toLowerCase() ? (
            <span
              key={index}
              style={{
                color: colors.text.primaryTheme,
                fontWeight: 'bold'
              }}
            >
              {part}
            </span>
          ) : (
            part
          )
        )}
        {end < message.length && '..'}
      </Typography>
    );
  };

  useEffect(() => {
    resetUserGroup();
  }, [location.pathname]);

  useEffect(() => {
    if (isLoggedIn) {
      userProfileDetails();
    }
  }, []);

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          width: '100%',
          justifyContent: 'space-between',
          flexDirection: { xs: 'column', lg: 'row' }
        }}
      >
        <TypographyWithI18N
          className='headerTitleBold ellipsisText'
          maxWidth={250}
          i18nKey={getTitle(pathnames[0])}
          textPrefix={
            (location.pathname !== '/' && pathnames[0] !== 'home') && (
              <BackArrowIcon
                onClick={() => navigate(-1)}
                style={{
                  position: 'relative',
                  left: -8,
                  height: 20,
                  cursor: 'pointer',
                  verticalAlign: 'middle',
                  paddingBottom: '2px'
                }}
              />
            )
          }
          sx={{
            alignItems: 'center',
            textTransform: 'capitalize',
            color: colors.text.primaryBlack,
            paddingBottom: '2px'
          }}
        />
        {pathnames[0] !== RoutesType.createPoll && (
        <Box display='flex' flexDirection='row'>
          <Box sx={{ position: 'relative', ml: 2 }}>
            <TextField
              autoComplete='off'
              placeholder={
                pathnames[0] === 'chat'
                  ? 'Search'
                  : 'Search Individuals, Groups etc.'
              }
              InputProps={{
                sx: {
                  height: 40,
                  width: 400,
                  pr: 0,
                  borderRadius: '30px',
                  background: '#EFEFEF',
                  '& fieldset': { border: 'none' }
                },
                startAdornment: (
                  <InputAdornment position='start'>
                    <SearchIcon />
                  </InputAdornment>
                ),
                endAdornment: (
                  <>
                    {userGroupQuery.length > 0 && (
                      <InputAdornment
                        position='start'
                        sx={{ cursor: 'pointer' }}
                        onClick={resetUserGroup}
                      >
                        <CloseRoundIcon />
                      </InputAdornment>
                    )}
                  </>
                )
              }}
              value={userGroupQuery}
              onChange={(event) => {
                if (isLoggedIn) {
                  setUserGroupQuery(event.target.value);
                  if (event.target.value != '') {
                    handleUserGroupSearch(event.target.value);
                  }
                } else {
                  setGuestModeOptions({
                    guestMode: true,
                    navigateTo: 'search',
                    menu: 'search',
                    redirectTo: '/home',
                    action: 'search',
                    actionItem: null
                  });
                  setOpenSigninDialog(true);
                }
              }}
            />
            {pathnames[0] !== 'chat' && showGroupList && (
              <ClickAwayListener onClickAway={resetUserGroup}>
                <Paper sx={{ position: 'absolute', top: 40, width: '100%' }}>
                  <MenuList sx={{ maxHeight: 250, overflowY: 'auto', px: 1.5 }}>
                    {userGroupList.length > 0 &&
                      userGroupList.map((_itm, index) => (
                        <MenuItem
                          key={index.toString()}
                          onClick={() => {
                            pathnames[0] === 'chat'
                              ? handleSearchResultClick(loggedUser?.id, _itm)
                              : navigate(
                                `/${_itm?.userActive ? 'profile' : 'group'}/${_itm?.id}`
                              );
                            resetUserGroup();
                          }}
                          sx={{
                            borderTop:
                              index === 0
                                ? 0
                                : `1px solid ${colors.border.primary}`,
                            padding: 1.5
                          }}
                        >
                          <AvatarImg
                            id='userImg'
                            enableLetterImg={_itm?.imageId ? false : true}
                            alt={_itm?.name}
                            src={_itm?.imageId ? _itm.imageUrl : ''}
                            width={32}
                            height={32}
                            name={_itm?.name}
                            nameStyle={{
                              fontSize: 12
                            }}
                            style={{
                              objectFit: 'cover',
                              borderRadius: '50%'
                            }}
                          />
                          <TypographyWithI18N
                            className='bodyText'
                            i18nKey={_itm.name}
                            ml={1}
                            color={colors.text.approxNero}
                            sx={{
                              maxWidth: '85%',
                              overflow: 'hidden',
                              textOverflow: 'ellipsis'
                            }}
                          />
                        </MenuItem>
                      ))}
                    {userGroupList.length === 0 && (
                      <MenuItem>No search users or groups</MenuItem>
                    )}
                  </MenuList>
                </Paper>
              </ClickAwayListener>
            )}
            {pathnames[0] === 'chat' && showGroupList && (
              <ClickAwayListener onClickAway={resetUserGroup}>
                <Paper sx={{ position: 'absolute', top: 40, width: '100%' }}>
                  {userList.length > 0 && (
                    <MenuList
                      sx={{ maxHeight: 280, overflowY: 'auto', px: 1.5 }}
                    >
                      <MenuItem>
                        <TypographyWithI18N
                          className='bodyText'
                          i18nKey={'People'}
                          color={colors.text.approxNero}
                          sx={{
                            maxWidth: '85%',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis'
                          }}
                        />
                      </MenuItem>
                      {userList.length > 0 &&
                        userList.map((_itm, index) => (
                          <MenuItem
                            key={index.toString()}
                            onClick={() => {
                              pathnames[0] === 'chat'
                                ? handleSearchResultClick(loggedUser?.id, _itm)
                                : navigate(
                                  `/${_itm?.userActive ? 'profile' : 'group'}/${_itm?.id}`
                                );
                              resetUserGroup();
                            }}
                            sx={{
                              borderTop:
                                index === 0
                                  ? 0
                                  : `1px solid ${colors.border.primary}`,
                              padding: 1.5
                            }}
                          >
                            <AvatarImg
                              id='userImg'
                              enableLetterImg={_itm?.imageId ? false : true}
                              alt={_itm?.name}
                              src={_itm?.imageId ? _itm.imageUrl : ''}
                              width={32}
                              height={32}
                              name={_itm?.name}
                              nameStyle={{
                                fontSize: 12
                              }}
                              style={{
                                objectFit: 'cover',
                                borderRadius: '50%'
                              }}
                            />
                            <TypographyWithI18N
                              className='bodyText'
                              i18nKey={_itm.name}
                              ml='8px'
                              color={colors.text.approxNero}
                              sx={{
                                maxWidth: '85%',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis'
                              }}
                            />
                          </MenuItem>
                        ))}
                    </MenuList>
                  )}
                  {groupList.length > 0 && (
                    <MenuList
                      sx={{ maxHeight: 280, overflowY: 'auto', px: 1.5 }}
                    >
                      <MenuItem>
                        <TypographyWithI18N
                          className='bodyText'
                          i18nKey={'Groups'}
                          color={colors.text.approxNero}
                          sx={{
                            maxWidth: '85%',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis'
                          }}
                        />
                      </MenuItem>
                      {groupList.map((_itm, index) => (
                        <MenuItem
                          key={index.toString()}
                          onClick={() => {
                            pathnames[0] === 'chat'
                              ? handleSearchResultClick(loggedUser?.id, _itm)
                              : navigate(
                                `/${_itm?.userActive ? 'profile' : 'group'}/${_itm?.id}`
                              );
                            resetUserGroup();
                          }}
                          sx={{
                            borderTop:
                              index === 0
                                ? 0
                                : `1px solid ${colors.border.primary}`,
                            padding: 1.5
                          }}
                        >
                          <AvatarImg
                            id='userImg'
                            enableLetterImg={_itm?.imageId ? false : true}
                            alt={_itm?.name}
                            src={_itm?.imageId ? _itm.imageUrl : ''}
                            width={32}
                            height={32}
                            name={_itm?.name}
                            nameStyle={{
                              fontSize: 12
                            }}
                            style={{
                              objectFit: 'cover',
                              borderRadius: '50%'
                            }}
                          />
                          <TypographyWithI18N
                            className='bodyText'
                            i18nKey={_itm.name}
                            ml='8px'
                            color={colors.text.approxNero}
                            sx={{
                              maxWidth: '85%',
                              overflow: 'hidden',
                              textOverflow: 'ellipsis'
                            }}
                          />
                        </MenuItem>
                      ))}
                    </MenuList>
                  )}
                  {messageList.length > 0 && (
                    <MenuList
                      sx={{ maxHeight: 280, overflowY: 'auto', px: 1.5 }}
                    >
                      <MenuItem>
                        <TypographyWithI18N
                          className='bodyText'
                          i18nKey={'Chats'}
                          color={colors.text.approxNero}
                          sx={{
                            maxWidth: '85%',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis'
                          }}
                        />
                      </MenuItem>
                      {messageList.map((_itm, index) => (
                        <MenuItem
                          key={index.toString()}
                          onClick={() => {
                            pathnames[0] === 'chat' &&
                              (_itm.isGroup
                                ? navigate(
                                  `/chat/groups/${_itm.conversationId}`
                                )
                                : navigate(`/chat/${_itm.conversationId}`));
                            resetUserGroup();
                          }}
                          sx={{
                            borderTop:
                              index === 0
                                ? 0
                                : `1px solid ${colors.border.primary}`,
                            padding: 1.5,
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center'
                          }}
                        >
                          <Box
                            display='flex'
                            alignItems='center'
                            sx={{ flex: 1, overflow: 'hidden' }}
                          >
                            <Box
                              display='flex'
                              justifyContent='start'
                              sx={{ width: '40px' }}
                            >
                              {_itm.isGroup
                                ? getGroupImage(_itm)
                                : getAvatars(_itm.participants)}
                            </Box>
                            <Box
                              display='flex'
                              flexDirection='column'
                              sx={{ marginLeft: 1, overflow: 'hidden' }}
                            >
                              <TypographyWithI18N
                                className={
                                  _itm.unreadCount !== 0
                                    ? 'bodyTextSmallBold'
                                    : 'bodyTextSmall'
                                }
                                i18nKey={
                                  _itm.isGroup
                                    ? _itm.groupName
                                    : getDisplayName(_itm.participants)
                                }
                                sx={{
                                  color: colors.text.primaryBlack,
                                  whiteSpace: 'nowrap',
                                  overflow: 'hidden',
                                  textOverflow: 'ellipsis',
                                  width: 150
                                }}
                              />
                              {HighlightedMessage(_itm, userGroupQuery)}
                            </Box>
                          </Box>
                          <Box
                            display='flex'
                            flexDirection='column'
                            alignItems='flex-end'
                            sx={{ marginLeft: 2 }}
                          >
                            <TypographyWithI18N
                              i18nKey={formatTimeDifference(_itm.message)}
                              fontSize={12}
                              sx={{
                                color: colors.text.approxSuvaGrey
                              }}
                            />
                            {_itm.unreadCount !== 0 && (
                              <Box
                                sx={{
                                  display: 'inline-flex',
                                  justifyContent: 'center',
                                  alignItems: 'center',
                                  backgroundColor: 'primary.main',
                                  color: 'primary.contrastText',
                                  borderRadius: '15px',
                                  height: '20px',
                                  width: '20px',
                                  fontSize: 14,
                                  fontWeight: 'bold',
                                  boxShadow: 1
                                }}
                              >
                                {_itm.unreadCount}
                              </Box>
                            )}
                          </Box>
                        </MenuItem>
                      ))}
                    </MenuList>
                  )}
                  {userList.length === 0 &&
                    groupList.length === 0 &&
                    messageList.length === 0 &&
                    userGroupQuery != '' && (
                      <MenuList>
                        <MenuItem>No search users or groups</MenuItem>
                      </MenuList>
                    )}
                </Paper>
              </ClickAwayListener>
            )}
          </Box>
          {isChatEnabled && pathnames[0] === 'chat' && (
            <Box alignContent='center'>
              <IconButton
                sx={{ padding: 0, ml: 2 }}
                onClick={() => setPopupOpen(true)}
              >
                <CreateMessage />
              </IconButton>
            </Box>
          )}
        </Box>
        )}
      </Box>
      {popupOpen && (
        <CreateMessagePopup
          open={popupOpen}
          onClose={() => setPopupOpen(false)}
          onMessageSent={(conversationId) => {
            setPopupOpen(false);
            navigate(`/chat/${conversationId}`);
          }}
        />
      )}
      {openSigninDialog && (
        <SigninModal
          guestModeOptions={guestModeOptions}
          openSigninDialog={openSigninDialog}
          closeSigninDialog={() => {setOpenSigninDialog(false)}}
        />
      )}
    </>
  );
};

export default Header;
