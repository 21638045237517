import { combineReducers, configureStore } from '@reduxjs/toolkit';

import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import authSlice from './authSlice';
import friendSlice from './friendSlice';
import featureFlagSlice from './featureFlagSlice';
import signalrSlice from './signalrSlice';
import utilsSlice from './utilsSlice';
import guestModeSlice from './guestModeSlice';

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['userAuth', 'featureFlag', 'signalr']
};

const rootReducer = combineReducers({
  userAuth: authSlice,
  friendList: friendSlice,
  featureFlag: featureFlagSlice,
  signalr: signalrSlice,
  utilsData: utilsSlice,
  guestMode: guestModeSlice
});

const persistedReducer = persistReducer(persistConfig, rootReducer);
export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      thunk: true,
      serializableCheck: false,
      immutableCheck: false
    })
});

export const persistor = persistStore(store);
export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
