import React, { useState } from 'react';
import DialogActionWall from './dialogActionWall';
import { Box, Link } from '@mui/material';
import { LogoIcon } from 'assets';
import TypographyWithI18N from './TypographyWithI18N';
import { colors } from 'styles/colors';
import AdminButton from './button';
import TermsOfUse from 'pages/authentication/termsOfUse';
import PrivacyPolicy from 'pages/authentication/privacyPolicy';
import { useAppDispatch } from 'store/hook';
import { useNavigate } from 'react-router-dom';
import { setGuestClickAction } from 'store/guestModeSlice';
import { IGuestModeOptions } from 'models/apiPaths';

interface ISigninModal {
  guestModeOptions: IGuestModeOptions;
  openSigninDialog: boolean;
  closeSigninDialog?: () => void;
}

const SigninModal = ({
  guestModeOptions,
  openSigninDialog,
  closeSigninDialog
}: ISigninModal) => {
  const navigate: any = useNavigate();
  const dispatch = useAppDispatch();
  const [openPrivacyModal, setOpenPrivacyModal] = useState(false);
  const [openTermsModal, setOpenTermsModal] = useState(false);
  const createAccountSignIn = (navigateTo: string) => {
    dispatch(
      setGuestClickAction({
        guestMode: guestModeOptions.guestMode ?? true,
        navigateTo: navigateTo,
        menu: guestModeOptions.menu ?? 'create account',
        redirectTo: guestModeOptions.redirectTo ?? '/signin',
        action: guestModeOptions.action ?? 'create-account',
        actionItem: guestModeOptions.actionItem ?? null
      })
    );
    navigate('/signin');
  };

  return (
    <>
      <DialogActionWall
        open={openSigninDialog}
        title=''
        dialogContentSxProps={{
          textAlign: 'center'
        }}
        dialogPaperSxProps={{
          padding: '20px',
          borderRadius: '20px'
        }}
        // loaderBtn={openDialog.loaderBtn}
        hideBtn={openSigninDialog}
        closeIconButton={openSigninDialog}
        onClose={closeSigninDialog}
        closeOnBackdropClick={true}
      >
        <LogoIcon style={{padding: '10px auto', marginTop: '50px'}} />
        <TypographyWithI18N
          i18nKey={"Get the full experience"}
          sx={{
            pt: 2,
            mt: 2,
            fontSize: '32px',
            fontWeight: 800,
            color: colors.text.primaryBlack
          }}
        />
        <TypographyWithI18N
          i18nKey={'Create account or login to get the full experience'}
          sx={{
            fontSize: '18px',
            color: colors.text.blackGray,
            mt: 1
          }}
        />
        <Box sx={{ fontSize: '12px', color: colors.text.blackGray, mt: 1}}>
          <span>By continuing, you agree to 4um's&nbsp;</span>
          <Link
            sx={{ color: colors.text.primaryTheme, cursor: 'pointer' }}
            underline='none'
            onClick={() => {
              setOpenPrivacyModal(false);
              setOpenTermsModal(true);
            }}
          >
            Terms of Use
          </Link>
          <span>&nbsp;and&nbsp;</span>
          <Link
            sx={{ color: colors.text.primaryTheme, cursor: 'pointer' }}
            underline='none'
            onClick={() => {
              setOpenPrivacyModal(true);
              setOpenTermsModal(false);
            }}
          >
            Privacy Policy
          </Link>
        </Box>
        <AdminButton
          title='Create Account'
          onClick={() => createAccountSignIn('create-account')}
          variant='outlined'
          role='presentation'
          fullWidth
          aria-label={` Button Press Enter to Create Account`}
          sxProps={{
            height: 44,
            mt:3,
            '&.MuiButton-outlined': {
              border: `1px solid ${colors.text.primaryTheme}`,
              background: colors.text.primaryTheme,
              color: colors.text.primaryWhite,
            },
          }}
        />
        <AdminButton
          title='Log in'
          onClick={() => createAccountSignIn('sign-in')}
          variant='outlined'
          role='presentation'
          fullWidth
          aria-label={` Button Press Enter to log in`}
          sxProps={{
            height: 44,
            '&.MuiButton-outlined': {
              border: `1px solid ${colors.text.approxVeryLightGrey}`,
              color: colors.text.darkGray,
              textTransform: 'capitalize',
              fontWeight: 600,
              mt: 1,
              '&:hover': {
                background: 'white'
              },
              boxShadow: 'none'
            },
          }}
        />
      </DialogActionWall>
      {openPrivacyModal && (
        <PrivacyPolicy openModal={openPrivacyModal} setOpenModal={setOpenPrivacyModal} />
      )}
      {openTermsModal && (
        <TermsOfUse openModal={openTermsModal} setOpenModal={setOpenTermsModal} />
      )}
    </>
  );
};

export default SigninModal;
