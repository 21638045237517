import React, { useState } from 'react';
import { Avatar, SxProps } from '@mui/material';
import { colors } from 'styles/colors';
import FullScreenView from './fullScreenView';
import DisableZoom from './DisableZoom';
import { IMediaItems } from './carousalItems';
import { useAppSelector } from 'store/hook';
import { RootState } from 'store';

export type nameVariant = 'xs' | 'sm' | 'md' | 'lg';
interface IAvatarImgProps {
  src: string;
  alt: string;
  id: string;
  width?: string | number;
  height?: string | number;
  name?: string;
  showBorder?: boolean;
  showFilter?: boolean;
  enableLetterImg?: boolean;
  style?: React.CSSProperties;
  nameStyle?: SxProps;
  nameVariant?: nameVariant;
  isLoggedInUserProfile?:boolean;
}

const AvatarImg: React.FC<IAvatarImgProps> = ({
  src,
  alt,
  id,
  width,
  height,
  showBorder,
  showFilter,
  name = '',
  enableLetterImg = true,
  nameStyle,
  nameVariant = 'sm',
  isLoggedInUserProfile,
  ...props
}) => {
  const dimension = {
    width: width,
    height: height
  };


  const stringToColor = (string: string) => {
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = '#';

    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */

    return color;
  };

  const getInitials = (text: string) => {
    const normalizedText = text.toLowerCase();
    const words = normalizedText.split(' ');
    if (words.length === 1) {
      let singleWord = words[0];
      singleWord = singleWord.replace(/[^a-zA-Z0-9]/g, '');
      if (/^[0-9]/.test(singleWord)) {
        const firstDigit = singleWord[0];
        const firstLetterMatch = singleWord.match(/[a-zA-Z]/);
        const firstLetter = firstLetterMatch ? firstLetterMatch[0].toUpperCase() : '';
        return firstLetter ? firstDigit + firstLetter : firstDigit;
      }
    }
    let initials = '';
    for (const word of words) {
      const cleanedWord = word.replace(/[^a-z0-9]/gi, '');
      if (cleanedWord) {
        initials += cleanedWord[0].toUpperCase();
      }
    }
    const numberMatch = normalizedText.match(/[0-9]/);
    if (numberMatch) {
      initials += numberMatch[0];
    }
    return initials;
  };

  const stringAvatar = (name: string) => {
    return {
      sx: {
        bgcolor: stringToColor(name),
        fontSize: 8,
        ...dimension,
        ...nameStyle
      },
      children: getInitials(name).substring(0, 2)
    };
  };

  const [imgLoad, setImageLoad] = useState<boolean>(false);
  const [fullScreen, setFullScreen] = useState(false);
  const [mediaItems, setMediaItems] = useState<IMediaItems[]>([]);

  const { isLoggedIn }: any = useAppSelector(
    (state: RootState) => state.userAuth
  );

  const onImageClick = (src: any) => {
    const image = {directUrl:src, fileName:name}
    setMediaItems([image])
    setFullScreen(true);
  }

  return (
    <>
   
      {!enableLetterImg && !imgLoad ? (
        <>
          <img
            alt={name}
            id={id}
            data-testid={id}
            src={src}
            {...props}
            style={{
              ...dimension,
              background: colors.text.lightShade,
              cursor:'pointer',
              ...props.style
            }}
            onError={() => {
              setImageLoad(true);
            }}
            onClick={() => {
              if (isLoggedIn) {
                onImageClick(src);
              }
            }}
          />
          {fullScreen && isLoggedInUserProfile && (
            <FullScreenView
              fullscreen={fullScreen}
              mediaItems={mediaItems}
              activeIndex={0}
              enableZoom={false} // Zoom functionality disabled
              closeFullScreen={() => {
                setFullScreen(false);
                setMediaItems([]);
              }}
            />
          )}
          <DisableZoom isEnabled={fullScreen}/>
        </>
      ) : (
        <Avatar {...stringAvatar(name)} />
      )}
    </>
  );
};

export default AvatarImg;
