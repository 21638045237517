import { Box, CircularProgress, Link } from '@mui/material';
import { RoutesType } from 'models/enum';
import TypographyWithI18N from 'pages/components/TypographyWithI18N';
import FriendRequest from 'pages/modules/notification/friendRequest';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { RootState } from 'store';
import { useAppDispatch, useAppSelector } from 'store/hook';
import { setGuestClickAction } from 'store/guestModeSlice';
import { colors } from 'styles/colors';
import ChatFriendsList from 'pages/modules/chats/chatsScreen';
import PrivacyPolicy from 'pages/authentication/privacyPolicy';
import TermsOfUse from 'pages/authentication/termsOfUse';
import { LogoIcon } from 'assets';
import AdminButton from '../components/button';

interface IRightSidePanel {
  isLoggedIn?: boolean;
}
const RightSidePanel = ({ isLoggedIn }: IRightSidePanel) => {
  const navigate: any = useNavigate();
  const location = useLocation();
  const { isFriendData }: any = useAppSelector(
    (state: RootState) => state.friendList
  );

  const pathnames = location.pathname.split('/').filter((x) => x)[0];
  const [active, setActive] = useState<boolean>(false);
  const [loader, setLoader] = useState<boolean>(false);
  const { isChatEnabled }: any = useAppSelector(
    (state: RootState) => state.featureFlag
  );

  const [openPrivacyModal, setOpenPrivacyModal] = useState(false);
  const [openTermsModal, setOpenTermsModal] = useState(false);
  const currentYear = new Date().getFullYear();
  const dispatch = useAppDispatch();

  const createAccountSignIn = (navigateTo: string) => {
    dispatch(
      setGuestClickAction({
        guestMode: true,
        navigateTo: navigateTo,
        menu: navigateTo,
        redirectTo: '/signin',
        action: navigateTo,
        actionItem: null
      })
    );
    navigate('/signin');
  };

  useEffect(() => {
    if (isFriendData && pathnames === RoutesType.notification) {
      setLoader(true);
      setTimeout(() => {
        setLoader(false);
      }, 1000);
    }
  }, [isFriendData]);

  return (
    isLoggedIn ? (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%'
      }}
    >
      {pathnames === 'chat' && <Box
        sx={{
          background: colors.background.approxAliceBlue,
          borderRadius: 2,
          mt: 2,
          flexGrow: 1,
          display: 'flex',
          flexDirection: 'column'
        }}
      >
        <TypographyWithI18N
          className='smallHeaderBold'
          i18nKey={pathnames !== 'chat' ? "What's Happening" : "Trending Groups"}
          sx={{
            p: 2,
            color: colors.text.primaryBlack,
            borderBottom: `1px solid ${colors.border.primary}`
          }}
        />
      </Box>}

      {pathnames === 'chat' && <Box
        sx={{
          background: colors.background.approxAliceBlue,
          borderRadius: 2,
          mt: 2,
          flexGrow: 1,
          display: 'flex',
          flexDirection: 'column'
        }}
      >
        <TypographyWithI18N
          className='smallHeaderBold'
          i18nKey={"Suggested For You"}
          sx={{
            p: 2,
            color: colors.text.primaryBlack,
            borderBottom: `1px solid ${colors.border.primary}`
          }}
        />
      </Box>}

      {pathnames !== 'chat' && <Box
        sx={{
          border: `1px solid ${colors.text.primaryTheme}`,
          background: colors.background.approxAliceBlue,
          borderRadius: 2,
          mt: 3
        }}
      >
        <TypographyWithI18N
          className='smallHeaderBold'
          i18nKey={'Add friends'}
          sx={{
            p: 2,
            color: colors.text.primaryBlack,
            borderBottom: `1px solid ${colors.border.primary}`
          }}
        />
        {!loader ? (
          <FriendRequest
            limit={-2}
            showLess={(action: boolean) => setActive(action)}
            btnShow={pathnames === RoutesType.notification}
          />
        ) : (
          <Box className='flexCenterJustify' height={150}>
            <CircularProgress size={24} />
          </Box>
        )}

        {active && pathnames !== RoutesType.notification && (
          <Link
            className='metadata'
            sx={{
              p: 2,
              color: colors.text.primaryTheme,
              display: 'flex',
              fontWeight: 500,
              cursor: 'pointer'
            }}
            underline='none'
            onClick={() =>
              navigate('notification', { state: { tabActive: 1 } })
            }
          >
            Show More
          </Link>
        )}
      </Box>}

      {isChatEnabled && pathnames !== 'chat' &&  <Box
        sx={{
          background: colors.background.approxAliceBlue,
          mt: 2,
          display: 'flex',
          overflowY: 'auto',
          flexDirection: 'column'
        }}
      >
        <ChatFriendsList />
      </Box>}

      <Box sx={{ margin: 'auto 0 16px', display: 'flex', alignItems: 'center', fontSize: '12px' }}>
        <Link
          className='bodyText'
          sx={{
            color: colors.text.primaryTheme,
            cursor: 'pointer',
            fontSize: '12px !important'
          }}
          underline='none'
          onClick={() => {
            setOpenPrivacyModal(false);
            setOpenTermsModal(true);
          }}
        >
          Terms of Service
        </Link>
        <span>&nbsp;|&nbsp;</span>
        <Link
          className='bodyText'
          sx={{
            color: colors.text.primaryTheme,
            cursor: 'pointer',
            fontSize: '12px !important'
          }}
          underline='none'
          onClick={() => {
            setOpenTermsModal(false);
            setOpenPrivacyModal(true); 
          }}
        >
          Privacy Policy
        </Link>
        <span>&nbsp;|&nbsp;</span>
        <TypographyWithI18N
          className='metadata'
          i18nKey={`© ${currentYear} 4um`}
        />
      </Box>

      {openPrivacyModal && (
        <PrivacyPolicy openModal={openPrivacyModal} setOpenModal={setOpenPrivacyModal} />
      )}
      {openTermsModal && (
        <TermsOfUse openModal={openTermsModal} setOpenModal={setOpenTermsModal} />
      )}
    </Box>
    ) : (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        textAlign: 'center',
      }}
    >
      <Box
        sx={{
          alignItems: 'center',
          paddingTop: '20px',
          margin: '20px auto',
          cursor: 'pointer'
        }}
      >
        <LogoIcon style={{padding: '10px auto'}} />
        <TypographyWithI18N
          i18nKey={"Join the conversation"}
          sx={{
            py: 2,
            mt: 2,
            fontSize: '24px',
            fontWeight: 800,
            color: colors.text.primaryBlack
          }}
        />
        <Box>
          <AdminButton
            title='Create Account'
            onClick={() => createAccountSignIn('create-account')}
            variant='outlined'
            role='presentation'
            aria-label={` Button Press Enter to Create Account`}
            sxProps={{
              height: 44,
              '&.MuiButton-outlined': {
                border: `1px solid ${colors.text.primaryTheme}`,
                background: colors.text.primaryTheme,
                color: colors.text.primaryWhite,
              },
            }}
          />
          <AdminButton
            title='Log in'
            onClick={() => createAccountSignIn('sign-in')}
            variant='outlined'
            role='presentation'
            aria-label={` Button Press Enter to Log in`}
            sxProps={{
              height: 44,
              ml: '12px',
              '&.MuiButton-outlined': {
                border: `1px solid ${colors.text.approxVeryLightGrey}`,
                background: colors.text.primaryWhite,
                color: colors.text.darkGray,
              },
            }}
          />
        </Box>
      </Box>
    </Box>
  )
  );
};

export default RightSidePanel;