import React, { useEffect, useState } from 'react';
import { Box, Menu, MenuItem } from '@mui/material';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import Collapse from '@mui/material/Collapse';
import {
  BellIcon,
  CheckGreenCircle,
  LogOutIcon,
  LogoIcon,
  LogoSmallIcon,
  MoreDotIcon
} from 'assets';
import { SideBarListType } from 'models/enum';
import { useLocation, useNavigate } from 'react-router-dom';
import { colors } from 'styles/colors';
import {
  CreateIcon,
  CreateActiveIcon,
  CreatePostIcon,
  CreatePollIcon,
  GroupsActiveIcon,
  GroupsIcon,
  HomeActiveIcon,
  HomeIcon,
  NotificationIcon,
  SettingsIcon,
  NotificationActiveIcon,
  ChatScreenActiveIcon,
  ChatScreenIcon
} from 'assets/icon';
import AvatarImg from 'pages/components/avatarImg';
import { useAppDispatch, useAppSelector } from 'store/hook';
import { RootState } from 'store';
import { setLogIn } from 'store/authSlice';
import TypographyWithI18N from 'pages/components/TypographyWithI18N';
import AdminIconButton from 'pages/components/iconButton';
import { useTranslation } from 'react-i18next';
import { userProfileUrl } from 'utilities/userImageURL';
import { ApiPaths, IGuestModeOptions } from 'models/apiPaths';
import {
  axiosPostUserDeleteRequest,
  createOrUpdateUserStatusActiveStatus,
  fetchUserDetails,
  getNotificationCount
} from 'services/api';
import ConfirmationModal from 'pages/components/confirmationModal';
import SigninModal from 'pages/components/signinModal';
import PersonRemoveIcon from '@mui/icons-material/PersonRemove';
import DialogActionWall from 'pages/components/dialogActionWall';
import isTokenExpired from 'utilities/authTokenValidation';
import { setFeatureFlag } from 'store/featureFlagSlice';
import { closeSignalRConnection } from 'services/SignalRService';

interface IListItem {
  title: string;
  active: boolean;
  count: number;
  subList?: IListItem[];
  link: string;
}
interface IOpenDialog {
  open: boolean;
  message?: string;
  label?: string;
  userId?: string;
  username?: string;
  loaderBtn?: boolean;
  confirmTxt?: boolean;
}
interface IOpenDialog {
  open: boolean;
  id?: string;
  loaderBtn?: boolean;
  successModal?: boolean;
}
interface ISideBar {
  isLoggedIn?: boolean;
}
const SideBar = ({ isLoggedIn }: ISideBar) => {
  const { t } = useTranslation();
  const navigate: any = useNavigate();
  const location = useLocation();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const { userProfile, authToken }: any = useAppSelector(
    (state: RootState) => state.userAuth
  );
  const [confirmModal, setConfirmModal] = React.useState<IOpenDialog>();
  const [isNotification, setIsNotification] = React.useState<boolean>(false);
  const [openDialog, setOpenDialog] = useState<IOpenDialog>({
    open: false,
    id: '',
    loaderBtn: false,
    successModal: false
  });
  const [guestModeOptions, setGuestModeOptions] = useState<IGuestModeOptions>({
    guestMode: false,
    navigateTo: null,
    menu: null,
    redirectTo: null,
    action: null,
    actionItem: null
  });
  const [openSigninDialog, setOpenSigninDialog] = useState<boolean>(false);
  const openMenu = Boolean(anchorEl);
  const { isChatEnabled }: any = useAppSelector(
    (state: RootState) => state.featureFlag
  );
  const listItems: IListItem[] = [
    {
      title: 'Home',
      active: true,
      count: 2,
      link: 'home'
    },
    {
      title: 'Groups',
      active: false,
      count: 0,
      link: 'groups'
    },
    {
      title: 'Create',
      active: false,
      count: 0,
      subList: [
        {
          title: 'Post',
          active: false,
          count: 0,
          link: 'createPost'
        }, {
          title: 'Poll',
          active: false,
          count: 0,
          link: 'createPoll'
        }
      ],
      link: 'create'
    },
    ...(isChatEnabled
      ? [
          {
            title: 'Chat',
            active: false,
            count: 0,
            link: 'chat'
          }
        ]
      : []),
    {
      title: 'Notification',
      active: false,
      count: 0,
      link: 'notification'
    }
  ];

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const [nestedMenuOpen, setNestedMenuOpen] = React.useState(true);

  const handleNestedMenuClick = () => {
    setNestedMenuOpen(!nestedMenuOpen);
  };

  const dispatch = useAppDispatch();
  const logOutClick = () => {
    setOpenDialog((pv: any) => ({
      ...pv,
      successModal: true
    }));
    createOrUpdateUserStatusActiveStatus({ online: false });
    setTimeout(() => {
      dispatch(
        setLogIn({
          authToken: null,
          isLoggedIn: false
        })
      );
      dispatch(
        setFeatureFlag({
          isChatEnabled: true
        })
      );
      if (isChatEnabled) {
        dispatch(closeSignalRConnection());
      }
      navigate('');
    }, 1000);
  };

  const userProfileDetails = async () => {
    const userProfile = await fetchUserDetails(ApiPaths.ME);
    dispatch(
      setLogIn({
        isLoggedIn: true,
        authToken: authToken,
        userProfile: {
          ...userProfile
        }
      })
    );
  };

  const notificationHandler = async () => {
    try {
      const notification = await getNotificationCount();
      if (notification) {
        Number(notification?.unreadNotifications) > 0
          ? setIsNotification(true)
          : setIsNotification(false);
      }
    } catch (error: unknown) {
      setIsNotification(false);
    }
  };

  useEffect(() => {
    if (isLoggedIn) {
      userProfileDetails();
    }
  }, [authToken]);

  useEffect(() => {
    if (userProfile?.notificationCount?.length > 0) {
      // const readCountValue =
      //   userProfile?.notificationCount.filter((x: any) => x > 0).length > 0;
      notificationHandler();
    }
  }, [userProfile?.notificationCount]);

  // isToken Expired
  useEffect(() => {
    if (isLoggedIn) {
      notificationHandler();
    }
  }, [location]);

  const iconList = (type: string, active: boolean) => {
    switch (type) {
      case SideBarListType.home:
        return active ? <HomeActiveIcon /> : <HomeIcon />;
      case SideBarListType.groups:
        return active ? <GroupsActiveIcon /> : <GroupsIcon />;
      case SideBarListType.create:
        return active ? <CreateActiveIcon /> : <CreateIcon />;
      case SideBarListType.chat:
        return active ? <ChatScreenActiveIcon /> : <ChatScreenIcon />;
      case SideBarListType.notification:
        return active ? (
          !isNotification ? (
            <BellIcon
              style={{
                width: 32,
                height: 32,
                marginLeft: '-4px',
                fill: colors.text.primaryTheme,
                strokeWidth: 0
              }}
            />
          ) : (
            <NotificationActiveIcon />
          )
        ) : !isNotification ? (
          <BellIcon
            style={{
              width: 32,
              height: 32,
              marginLeft: '-4px'
            }}
          />
        ) : (
          <NotificationIcon />
        );
      case SideBarListType.settings:
        return active ? <SettingsIcon /> : <SettingsIcon />;
      case SideBarListType.createPost:
        return active ? <CreatePostIcon /> : <CreatePostIcon />;
      case SideBarListType.createPoll:
        return active ? <CreatePollIcon /> : <CreatePollIcon />;
      default:
        return false;
    }
  };
  const itemSelected = (link: string, subList?: IListItem[]) => {
    const pathNames = location.pathname.split('/').filter((x) => x);
    return subList? pathNames[0]?.startsWith(link) : pathNames[0] === link ? true : false;
  };

  const userNameActive = (userId: string) => {
    const pathId = location.pathname.split('/').filter((x) => x)[1];
    return userId === pathId;
  };

  const openSigninModal = (itm: IListItem | undefined) => {
    setGuestModeOptions({
      guestMode: true,
      navigateTo: itm?.link || 'home',
      menu: itm?.title || 'home',
      redirectTo: '/' + (itm?.link || 'home'),
      action: itm?.title || 'home',
      actionItem: null
    });
    setOpenSigninDialog(true);
  }

  const buttonProps = (itm: IListItem) => (
    itm.subList ? {
        onClick: handleNestedMenuClick,
    } : {
      selected: itm.link? itemSelected(itm.link) : undefined,
      onClick: isLoggedIn ? () => { navigate('/' + itm.link); } : () => { openSigninModal(itm) },
    }
  );

  const accountDelete = async () => {
    setConfirmModal((pv) => ({
      ...pv,
      open: true,
      loaderBtn: true
    }));
    try {
      const payload = {
        reason: 'Request to Delete Account'
      };
      await axiosPostUserDeleteRequest(payload);
      // console.log('deleteReq', deleteReq);
    } catch (err) {
    } finally {
      setConfirmModal((pv) => ({
        ...pv,
        open: true,
        loaderBtn: false,
        message: `The delete account request is sent successfully. Your account is scheduled for deletion within next 30 days.`,
        confirmTxt: false
      }));
    }
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%'
      }}
    >
      <Box
        sx={{
          height: 70,
          display: 'flex',
          alignItems: 'center',
          margin: '0 auto',
          cursor: 'pointer'
        }}
        onClick={() => navigate('/')}
      >
        <LogoSmallIcon />
      </Box>

      <List sx={{ margin: '0 auto', width: { md: 200 } }}>
        {listItems.map((_itm, index) => (
          <>
            <ListItem
              key={`${index}_list`}
              {...buttonProps(_itm)}
              sx={{
                cursor: 'pointer',
                height: 50,
                justifyContent: 'center',
                '&.Mui-selected': {
                  background: colors.background.white
                }
              }}
            >
              <ListItemIcon
                sx={{
                  '&.MuiListItemIcon-root': {
                    xs: {
                      minWidth: 'auto'
                    },
                    md: {
                      minWidth: 56
                    }
                  }
                }}
              >
                {iconList(_itm.title, itemSelected(_itm.link, _itm.subList))}
              </ListItemIcon>

              <ListItemText
                primary={_itm.title}
                primaryTypographyProps={{
                  color: itemSelected(_itm.link, _itm.subList)
                    ? colors.text.primaryTheme
                    : colors.text.primaryBlack,
                  fontSize: '18px',
                  fontStyle: 'normal',
                  fontWeight: itemSelected(_itm.link, _itm.subList) ? '700' : '400',
                  lineHeight: 'normal',
                  display: { xs: 'none', md: 'block' }
                }}
              />
              {_itm.subList ? nestedMenuOpen ? <ExpandLess /> : <ExpandMore /> : null}
            </ListItem>
            {_itm.subList && (
              <Collapse in={nestedMenuOpen} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  {_itm.subList.map((_subItm, index) => (
                    <ListItem
                      key={`${index}_list`}
                      {...buttonProps(_subItm)}
                      sx={{
                        pl: 6,
                        cursor: 'pointer',
                        height: 50,
                        justifyContent: 'center',
                        '&.Mui-selected': {
                          background: colors.background.white
                        }
                      }}
                    >
                      <ListItemIcon
                        sx={{
                          '&.MuiListItemIcon-root': {
                            xs: {
                              minWidth: 'auto'
                            },
                            md: {
                              minWidth: 40
                            }
                          }
                        }}
                      >
                        {iconList(_subItm.title, itemSelected(_subItm.link))}
                      </ListItemIcon>

                      <ListItemText
                        primary={_subItm.title}
                        primaryTypographyProps={{
                          color: itemSelected(_subItm.link)
                            ? colors.text.primaryTheme
                            : colors.text.primaryBlack,
                          fontSize: '18px',
                          fontStyle: 'normal',
                          fontWeight: itemSelected(_subItm.link) ? '700' : '400',
                          lineHeight: 'normal',
                          display: { xs: 'none', md: 'block' }
                        }}
                      />
                    </ListItem>
                  ))}
                </List>
              </Collapse>
            )}
          </>
        ))}
      </List>
      {isLoggedIn ? (
      <>
        <Box
          id='userDetails'
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            margin: 'auto 0 16px',
            flexDirection: { xs: 'column', lg: 'row' },
            width: { md: 300 },
            marginLeft: { md: 'auto' }
          }}
        >
          <Box
            className='flexCenterJustify'
            ml={{ lg: 2 }}
            onClick={() => navigate(`/profile/${userProfile?.id}`)}
            sx={{ cursor: 'pointer', flexDirection: { xs: 'column', lg: 'row' } }}
          >
            <AvatarImg
              enableLetterImg={userProfile?.userImage?.id ? false : true}
              src={
                userProfile?.userImage?.id
                  ? userProfileUrl(userProfile?.userImage.id)
                  : ''
              }
              alt='user profile'
              id='user_profile'
              name={userProfile?.username}
              width={36}
              height={36}
              nameStyle={{
                fontSize: 14
              }}
              style={{
                borderRadius: '50%',
                background: colors.text.approxLightShade,
                objectFit: 'cover'
              }}
            />
            <Box flex={1} display={{ xs: 'none', md: 'block' }}>
              <TypographyWithI18N
                className='bodyTextBold'
                i18nKey={userProfile?.username}
                sx={{
                  pl: 1,
                  color: userNameActive(userProfile?.id)
                    ? colors.text.primaryTheme
                    : colors.text.primaryBlack
                }}
              />
              <TypographyWithI18N
                i18nKey={userProfile?.email}
                sx={{
                  pl: 1
                }}
              />
            </Box>
          </Box>

          {confirmModal?.open && (
            <ConfirmationModal
              open={confirmModal.open}
              message={confirmModal.message}
              title={'Request to Delete Account'}
              successLabel={'Delete'}
              loaderBtn={confirmModal.loaderBtn}
              confirmTxt={confirmModal.confirmTxt}
              onSuccess={accountDelete}
              onExit={() =>
                setConfirmModal((pv) => ({
                  ...pv,
                  open: false
                }))
              }
            />
          )}

          <AdminIconButton
            id='long-button'
            aria-label='more'
            onClick={handleClick}
            icon={<MoreDotIcon />}
          />

          <Menu
            anchorEl={anchorEl}
            id='account-menu'
            open={openMenu}
            onClose={handleClose}
            onClick={handleClose}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right'
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right'
            }}
          >
            <MenuItem
              onClick={() => {
                setConfirmModal((pv) => ({
                  ...pv,
                  open: true,
                  message: `Are you sure you want to delete your account? \n This action cannot be undone.`,
                  confirmTxt: true
                }));
              }}
            >
              <ListItemIcon>
                <PersonRemoveIcon fontSize='small' />
              </ListItemIcon>
              Request to delete account
            </MenuItem>

            <MenuItem
              onClick={() => {
                setOpenDialog({
                  open: true
                });
              }}
            >
              <ListItemIcon>
                <LogOutIcon fontSize='small' />
              </ListItemIcon>
              {t('logOut')}
            </MenuItem>
          </Menu>
        </Box>
        {openDialog.open && (
          <DialogActionWall
            open={openDialog.open}
            title={openDialog.open && !openDialog.successModal ? 'Logout' : ''}
            dialogTitleSxProps={{
              textAlign: 'center'
            }}
            dialogActionsSxProps={{
              justifyContent: 'center'
            }}
            onSuccess={logOutClick}
            onCancel={() =>
              setOpenDialog((pv: any) => ({
                ...pv,
                open: false
              }))
            }
            onSuccessBtnColor={'error'}
            loaderBtn={openDialog.loaderBtn}
            successLabel={'Logout'}
            dialogSuccessSxProps={{
              opacity: openDialog.loaderBtn ? 0.6 : 1,
              pointerEvents: openDialog.successModal ? 'none' : ''
            }}
            hideBtn={!openDialog.successModal}
            closeIconButton={openDialog.successModal}
            onClose={() => {
              setOpenDialog((pv: any) => ({
                ...pv,
                open: false,
                successModal: false
              }));
            }}
          >
            {openDialog.successModal ? (
              <Box className='flexCenterJustify flexCenterColumn'>
                <CheckGreenCircle />
                <TypographyWithI18N
                  mt={3}
                  className='headerBold'
                  color={colors.text.primaryBlack}
                  i18nKey={'You have successfully logged out from 4um.'}
                />
              </Box>
            ) : (
              <TypographyWithI18N
                textAlign={'center'}
                i18nKey={'Are you sure you want to logout the 4um?'}
              />
            )}
          </DialogActionWall>
        )}

      </>
      ) : (
        openSigninDialog && (
          <SigninModal
            guestModeOptions={guestModeOptions}
            openSigninDialog={openSigninDialog}
            closeSigninDialog={() => {setOpenSigninDialog(false)}}
          />
        )
      )}
    </Box>
  );
};

export default SideBar;
