import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  guestMode: false,
  navigateTo: null,
  menu: null,
  redirectTo: null,
  action: null,
  actionItem: null
};

const guestModeSlice = createSlice({
  name: 'guestModeSlice',
  initialState,
  reducers: {
    setGuestClickAction: (state, action) => {
      state.guestMode = action.payload.guestMode;
      state.navigateTo = action.payload.navigateTo;
      state.menu = action.payload.menu;
      state.redirectTo = action.payload.redirectTo;
      state.action = action.payload.action;
      state.actionItem = action.payload.actionItem;
    },
    clearGuestAction: (state) => {
      state.guestMode = initialState.guestMode;
      state.navigateTo = initialState.navigateTo;
      state.menu = initialState.menu;
      state.redirectTo = initialState.redirectTo;
      state.action = initialState.action;
      state.actionItem = initialState.actionItem;
    }
  }
});

export const { setGuestClickAction, clearGuestAction } = guestModeSlice.actions;

export default guestModeSlice.reducer;
