import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  groupNameData: null,
  profileNameData: null
};

const utilsSlice = createSlice({
  name: 'utilsData',
  initialState,
  reducers: {
    setGroupNameData: (state, action) => {
      state.groupNameData = action.payload.groupNameData;
    },
    setProfileNameData: (state, action) => {
      state.profileNameData = action.payload.profileNameData;
    }
  }
});

export const { setGroupNameData, setProfileNameData } = utilsSlice.actions;

export default utilsSlice.reducer;
