export enum SideBarListType {
  home = 'Home',
  groups = 'Groups',
  create = 'Create',
  chat = 'Chat',
  notification = 'Notification',
  settings = 'Settings',
  createPost = 'Post',
  createPoll = 'Poll',
}

export enum stepType {
  login = 'Login',
  register = 'Register',
  otp = 'OTP',
  forgotPassword = 'Forgot Password',
  resetPassword = 'Reset Password',
  interestSkills = 'Interest Skills'
}

export enum ActionsType {
  add = 0,
  edit = 1,
  delete = 2
}

export enum DurationType {
  minutes = 'minutes',
  seconds = 'seconds'
}

export enum RoutesType {
  home = 'home',
  viewPost = 'post',
  groups = 'groups',
  createPost = 'createPost',
  createPoll = 'createPoll',
  chat = 'chat',
  profilePage = 'profile',
  groupPost = 'group',
  createGroup = 'createGroup',
  notification = 'notification',
  friendList = 'friendlist',
  groupList = 'grouplist'
}

export enum RoutesTypeTitle {
  home = 'Home',
  viewPost = 'View Post',
  groups = 'Groups',
  createPost = 'create Post',
  createPoll = 'create Poll',
  chat = 'Chat',
  profilePage = 'Profile',
  groupPost = 'Group Post',
  createGroup = 'Create Group',
  notification = 'Notification',
  friendlist = 'Friend List',
  grouplist = 'Groups List'
}

export enum NotificationTimeBound {
  NEW = 'New',
  LAST7_DAYS = 'Last 7 days',
  LAST_MONTH = 'Last Month',
  LAST_YEAR = 'Last Year',
  OLDER = 'Older'
}

export enum NotificationActionType {
  POST_COMMENTED = 'POST_COMMENTED',
  POST_VOTED = 'POST_VOTED',
  POST_CREATED = 'POST_CREATED'
}

export enum FriendRequestStatus {
  ACCEPTED = 'accepted',
  REJECTED = 'rejected'
}

export enum EmailPhoneGroup {
  Email = 'Email',
  Phone = 'Phone'
}
