import { colors } from 'styles/colors';

const TermsOfUse = () => {
  return (
    <>
      <div style={{textAlign:'justify'}}>
        <div>
          <p style={{ textAlign: 'center' , color: colors.text.primaryTheme}}>
            <strong>TERMS OF USE</strong>
          </p>
        </div>
        <div>
          <p>
            Welcome to 4um! We are honored to have you join us to help build a
            community based on solidarity where everyone deserves a voice and
            a chance to be heard. 4um LLC (referred to collectively as
            “<strong style={{ color: colors.text.primaryTheme }}>4um</strong>”,
            “<strong style={{ color: colors.text.primaryTheme }}>our</strong>”
            , “<strong style={{ color: colors.text.primaryTheme }}>us</strong>”,
            or “<strong style={{ color: colors.text.primaryTheme }}>we</strong>”) is
            an organization operating under the laws of Delaware, located at 8 The Green
            , Suite A, Dover, Delaware 19901. The following Terms of Use (or “
            <strong style={{ color: colors.text.primaryTheme }}>Agreement</strong>”) governs use of our platform. By using our
            platform, you agree that this Agreement constitutes a binding contract
            between you and 4um. The following Agreement outlines the responsibilities
            and allocates liability between 4um and all end users of our platform.
            Please read and make sure you understand this Agreement and 4um’s company
            Policies (“<strong style={{ color: colors.text.primaryTheme }}>Policies</strong>”)
            that govern use of our Platform. If you do not accept or understand any of the
            terms in this Agreement or company Polices governing the use of 4um,
            then you are prohibited from using our platform.
          </p>
        </div>

        <div>
          <p style={{ textAlign: 'center' , color: colors.text.primaryTheme}}>
            <strong>4UM'S OFFERING</strong>
          </p>
        </div>
        <div>
          <p>
            4um provides users with social media services which includes all software,
            services, technologies, features, products, intellectual property,
            trademarks, know-how, interactive metrics, and communications we make
            available to you on the platform (collectively, our “<strong style={{ color: colors.text.primaryTheme }}>Offering</strong>”).
            Subject to the terms of this Agreement and our company Policies,
            4um grants users a nonexclusive right and license to access and use our Offering.
          </p>
        </div>

        <div>
          <p style={{ textAlign: 'center' , color: colors.text.primaryTheme}}>
            <strong>PRIVACY POLICY</strong>
          </p>
        </div>
        <div>
          <p>
            4um is made possible through advertisements, subscriptions, paid offerings,
            and donations. We will collect and utilize the information you provide us
            by accessing and using our platform. 4um’s Privacy Policy summarizes the
            protocols that our platform employs for gathering, utilizing, and
            disseminating user information to sponsors and all members across our
            community and how users can manage the information they share on our platform.
            BY USING 4UM ALL USERS AGREE TO OUR PRIVACY POLICY.
          </p>
        </div>

        <div>
          <p style={{ textAlign: 'center' , color: colors.text.primaryTheme}}>
            <strong>WHO MAY USE 4UM</strong>
          </p>
        </div>
        <div>
          <p>For all users of 4um, the following stipulations apply</p>
          <ul style={{listStyleType: 'decimal'}}>
            <li>Users must be 13 years of older to use 4um.</li>
            <li>
              Anyone under 18 using 4um confirms that they have obtained permission from
              a parent or legal guardian to do so. Parents or legal guardians of children
              under 18 who use our platform represent and warrant that they are bound by
              this Agreement and the Policies governing use of the platform and that they
              are responsible for their child’s actions on 4um.
            </li>
            <li>
              If you are utilizing 4um on behalf of a business, company, or organization,
              you confirm that said entity accepts these Agreement and that you have the
              legal authority to act on said entity’s behalf.
            </li>
            <li>You may not use 4um if you are barred from using our Offering under applicable laws or regulations.</li>
            <li>You may not use 4um if your account was terminated because of infractions of our Agreement or any applicable laws.</li>
            <li>You may not use 4um if you are a convicted sex offender.</li>
          </ul>
        </div>

        <div>
          <p style={{ textAlign: 'center' , color: colors.text.primaryTheme}}>
            <strong>RESTRICTIONS</strong>
          </p>
        </div>
        <div>
          <p>
            We want to build a community based on solidarity where everyone has an equal
            chance to be heard. To ensure the integrity and wellbeing of our community,
            the following restrictions apply:
          </p>
          <ul style={{listStyleType: 'decimal'}}>
            <li>You may not engage in any actions that breach 4um’s Agreement or Policies.</li>
            <li>
              You may not engage in any actions that impair the functionality of our platform
              or our Offering. This includes: (i) using fake accounts, bots, or paying people
              to artificially inflate likes, followers, views, comments, or other user metrics
              in any manner that could cause inauthentic user engagement; (ii) submitting frivolous,
              unsubstantiated, or irksome submissions that disrupt or exploit our complaint, reporting,
              or appeals process; or (iii) circumventing, disabling, or interfering with security features.
            </li>
            <li>
              You may not use botnets, robots, scrappers, or any other means to pull information from
              our platform for commercial means that do not benefit our community.
            </li>
            <li>
              You may not use the platform to sell or promote any advertising, marketing, contests,
              sponsorships, product placements, or promotions to members of our community unless you
              receive prior written consent from us to do so.
            </li>
            <li>
              You may not use our platform to spam or disseminate unsolicited commercial or promotional Content.
            </li>
            <li>
              You may not engage in disingenuous, deceitful, or unlawful activities on our platform.
            </li>
            <li>You may not violate intellectual property rights on our platform.</li>
            <li>You may not violate the legal rights of others on our platform.</li>
            <li>You may not post confidential or private information of others without their consent on our platform.</li>
            <li>
              You may not not sell, purchase, or license user accounts or data from
              our platform without obtaining written permission from us to do so.
            </li>
            <li>
              You may not may not access, copy, disseminate, modify, or sell any of
              the services in our Offering or Content contained therein unless
              (i) expressly authorized or (ii) you have received prior written consent from us to do so.
            </li>
          </ul>
        </div>

        <div>
          <p style={{ textAlign: 'center' , color: colors.text.primaryTheme}}>
            <strong>USE OF OUR OFFERING</strong>
          </p>
        </div>
        <div>
          <p>
            The uploading of audio, images, photos, videos, and text 
            (i.e. “<strong>Content</strong>”) on 4um is the responsibility of the user.
            As a user you are legally responsible for all Content that you upload on
            our platform. Users may upload and access Content so long as they comply
            with our Agreement and Polices. 4um is under no binding obligation to host
            Content uploaded by users and we may remove Content and user profiles from
            our platform if it violates our Agreement or Policies. If users believe certain
            Content violates the Policies governing our platform, they may report said
            violations to us by flagging it or sending an email to <u style={{ color: colors.text.primaryTheme}}>admin@4um.com</u>.
            Using our platform does not grant users ownership or legal rights to any
            Content or other part of our Offering.
          </p>
        </div>

        <div>
          <p style={{ textAlign: 'center' , color: colors.text.primaryTheme}}>
            <strong>YOU AGREE TO</strong>
          </p>
        </div>
        <div>
          <p>
            Under our Agreement, by using 4um users agree to the following stipulations
            to help us build our platform and better serve our community:
          </p>
          <ul style={{listStyleType: 'decimal'}}>
            <li>
              By uploading Content to our platform, users grant 4um with a perpetual,
              conveyable, free from royalty, global, nonexclusive, sublicensable license
              to disseminate, duplicate, host, publicly perform or display, transmit,
              create derivative works, or utilize their Content in connection with our
              Offering. This includes any Content that you post, share, or upload
              such as audio, pictures, text, or videos.
            </li>
            <li>
              Users grant us a perpetual, conveyable, free from royalty, global,
              nonexclusive, sublicensable license to all other users to access
              and utilize your Content via our platform.
            </li>
            <li>
              Subject to the terms of our privacy policy, users grant us the
              right us their demographics and interactions with our Offering
              to tailor and share the advertisements, Content,
              and experience most relevant to you.
            </li>
            <li>Users agree that we can install updates to our Offering on your device.</li>
          </ul>
        </div>

        <div>
          <p style={{ textAlign: 'center' , color: colors.text.primaryTheme}}>
            <strong>CONTENT REMOVAL, ACCOUNT RESTRICTION ACCOUNT SUSPENSION, & BAN</strong>
          </p>
        </div>
        <div>
          <p>
            4um reserves the right to remove any Content from our platform,
            restrict functionalities of your account, suspend your account,
            or permanently ban you from our platform if (i) you violate our
            Agreement or any of our company Polices, (ii) if we believe that
            your behavior causes or could cause harm or liability, risk, or
            legal exposure to our platform, any of the users on our platform,
            or any other parties, or (iii) to comply with a legal requirement
            or court order.
          </p>
          <p>
            If Content from your profile is removed, your account is restricted
            or suspended, or if you are permanently banned from our platform,
            you may appeal our decision by sending an
            email <u style={{ color: colors.text.primaryTheme}}>admin@4um.com</u> and
            providing the rational for your appeal.  Any reinstatement of
            Content or access to our platform is a 4um’s sole discretion.
          </p>
        </div>

        <div>
          <p style={{ textAlign: 'center' , color: colors.text.primaryTheme}}>
            <strong>ACCOUNT DELETION</strong>
          </p>
        </div>
        <div>
          <p>
            Users may delete their account at any time by going to settings and
            choosing the option to delete their profile from our platform.
            Upon your request, we will begin the deletion process. During the
            deletion process, your Content will no longer accessible by users
            our platform but will continue to be governed by our Agreement and
            Policies. The deletion process will take no longer than thirty (30)
            days to complete, though your information may be retained in our data
            servers for a period not to exceed one hundred twenty (120) days
            following your request for deletion. Under the following circumstances,
            your data may be retained for longer than one hundred twenty (120) days:
          </p>
          <ul style={{listStyleType: 'decimal'}}>
            <li>Where deletion of your data would impair our capacity to comply
              with legal requests or examine violations or potential violations
              of our Agreement or Policies;
            </li>
            <li>Where technical restrictions prevent us from fulfilling deletion of your account;</li>
            <li>Where your Content or information is being used by other users on our platform.</li>
          </ul>
        </div>

        <div>
        <p style={{ textAlign: 'center' , color: colors.text.primaryTheme}}>
            <strong>ARBITRATION</strong>&nbsp;
          </p>
        </div>
        <div>
          <p>
            BY ACCEPTING THIS AGREEMENT, USERS CONSENT THAT ANY CLAIM(S) ARISING
            OUT OF OR RELATING TO THIS AGREEMENT, OR YOUR USE OF 4UM’S OFFERING,
            WILL BE RESOLVED BY BINDING ARBITRATION ON AN INDIVIDUAL BASIS.
            YOU HEREBY WAIVE YOUR RIGHT TO TRIAL BY JURY AND RIGHT TO PARTICIPATE
            IN A CLASS ACTION LAWSUIT OR CLASS-WIDE ARBITRATION.
          </p>
          <p>
            <strong>Claims Subject to Arbitration: </strong>
            Except as provided below, any legal claim, cause of action, or dispute
            between you and 4um arising out of or relating to this Agreement,
            or your use of 4um’s Offering, must be resolved through binding arbitration
            on an individual basis. This Arbitration Provision (“<strong>Arbitration Provision</strong>”)
            is governed by the Federal Arbitration Act (“<strong>FAA</strong>”). By agreeing
            to arbitrate, both you and 4um expressly waive the right to sue in a court of
            law or pursue a trial by jury. This agreement extends to any disputes involving
            4um’s affiliates, agents, successors, and assigns.
          </p>
          <p>
            <strong>No Class Actions: </strong>
            All claims, causes of action, and disputes under this Agreement must be
            arbitrated on an individual basis. Neither you nor 4um may bring a claim
            as a plaintiff or class member in a class, collective, mass, or joint action.
          </p>
          <p>
            <strong>Arbitration Rules: </strong>
            Under this Agreement, Arbitration will be administered by the American Arbitration
            Association (“AAA”) in accordance with its Consumer Arbitration Rules. More
            information on the AAA and its Consumer Arbitration Rules can be found at
            the organization’s website (<a href="www.adr.org">www.adr.org</a>).
            Except as required by law or by a need to disclose information to Consultants
            (“Consultants” such as accountants, auditors, or insurance providers),
            parties agree to maintain confidentiality during arbitration proceedings.
            Arbitration proceedings, if parties agree, will occur by teleconference or
            videoconference. If parties or the arbitrator decide that arbitration proceedings
            must be in-person, all arbitration hearings will occur in Dover, Delaware.
            Under a dispute, the arbitrator(s) will be responsible for deciding all matters
            except that a court of law may decide matters regarding: (a) interpretation of
            this Arbitration Provision; (b) whether a dispute may be arbitrated; or (c)
            enforceability and scope of this Arbitration Provision. Judgment on an arbitration
            award may be entered by any court having jurisdiction.
          </p>
          <p>
            <strong>Arbitration Process: </strong>
            Before you or 4um initiate arbitration, the party seeking arbitration must
            provide the other party with a written Notice of Dispute (“<strong>Notice of Dispute</strong>”).
            This Notice of Dispute must include: (a) the name of the person alleging the dispute,
            (b) that person’s contact information, (c) a detailed description of the nature and
            basis of the dispute, and (d) the specific relief sought. If the dispute is not
            resolved within 60 days after the Notice of Dispute is received, either party may
            commence arbitration. All Notices of Dispute or other communications related to
            this Arbitration Provision should be sent by certified U.S., Federal Express, UPS,
            or international shipping carrier to:
          </p>
          <ul style={{listStyle: 'none'}}>
            <li><strong>4um Arbitration Committee</strong></li>
            <li><strong>8 The Green, Suite 18572</strong></li>
            <li><strong>Dover, DE 19901</strong></li>
          </ul>
          <p>
            <strong>30-Day Right to Opt-Out: </strong>
            You have the right to opt-out of this Arbitration Provision within 30 days
            of first using 4um’s Offering by sending a written Opt-Out Notice
            (“<strong>Opt-Out Notice</strong>”) to the address provided above.
            The Opt-Out Notice must include: (a) your name, (b) your contact information,
            and (c) a clear statement that you wish to opt out of arbitration.
            Opting out of this arbitration provision will not affect any other terms of this Agreement.
          </p>
          <p>
            <strong>Arbitration Costs: </strong>
            The initiator of any arbitration proceeding(s) will be required to pay all related
            American Arbitration Association filing fees and arbitration costs. The arbitrator
            of the proceeding(s) may, in his or her sole discretion, allocate all or part of the
            costs of the arbitration in the award or judgment, including the fees of the arbitrator
            and the reasonable attorneys’ fees of the parties.
          </p>
          <p>
            <strong>Governing Law and Judicial Forum: </strong>
            This arbitration provision is governed by the Federal Arbitration Act (FAA) and,
            to the extent not preempted by federal law, the laws of the State of Delaware.
            If for any reason a claim proceeds to court rather than arbitration, parties agree
            to the personal and exclusive jurisdiction of the federal or state courts located
            in Dover, Delaware to litigate any such claim.
          </p>
        </div>

        <div>
          <p style={{ textAlign: 'center' , color: colors.text.primaryTheme}}>
            <strong>LIMITATION ON LEGAL ACTIONS</strong>
          </p>
        </div>
        <div>
          <p>
          ALL USERS AGREE THAT ANY CAUSE OF ACTION RESULTING FROM OR CONNECTED TO
          THE PLATFORM’S OFFERING, AGREEMENT, OR POLICIES MUST BE INITIATED WITHIN
          ONE (1) YEAR FOLLOWING THE DATE THAT THE CAUSE OF ACTION COMES INTO EXISTENCE.
          ANY CAUSE OF ACTION THAT IS NOT INITIATED WITHIN ONE (1) YEAR FROM THE DATE
          IT COMES INTO EXISTENCE IS FOREVERMORE DISQUALIFIED.
          </p>
        </div>

        <div>
          <p style={{ textAlign: 'center' , color: colors.text.primaryTheme}}>
            <strong>MISCELLANEOUS PROVISIONS</strong>&nbsp;
          </p>
        </div>
        <div>
          <p>
            <strong>Disclaimer of Warranty: </strong>
            4UM IS PROVIDED “AS IS” AND WE DO NOT MAKE ANY EXPLICIT OR IMPLICIT
            COMMITMENTS, GUARANTEES, OR WARRANTIES REGARDING OUR OFFERING.
          </p>
          <p>
            <strong>Governing Law: </strong>
            This Agreement will be governed by and construed in accordance with the
            State laws of Delaware in the United States of America applicable to
            agreements made and to be fully performed therein.
          </p>
          <p>
            <strong>Indemnification: </strong>
            Users of 4um agree to defend, indemnify and hold harmless 4um,
            its affiliates and their respective control persons, managers,
            members, stockholders, officers, employees and agents (each an
            “<strong>Indemnified Party</strong>”) to the fullest legal extent
            from and against any and all claims, losses, damages, liabilities,
            penalties, judgments, costs of settlement, awards, costs and expenses
            as incurred (including if we remove your Content or delete your account
            and all reasonable fees and disbursements of counsel)
            (collectively “<strong>Losses</strong>”), arising out of or related to:
            (i) your use of and access to our Offering; (ii) your violation of any
            terms of this Agreement or of our Policies; (iii) your breach of any
            third party right, including any intellectual property or privacy right;
            or (iv) any allegation that your Content resulted in damages to a third party.
            This indemnity provision will survive termination of this Agreement
            and your use of our Offering.
          </p>
          <p>
            <strong>Interpretation: </strong>
            Within these Agreement and our other Policies, the terminology “include” or
            “including” is defined to mean “including but not limited to.”
          </p>
          <p>
            <strong>Limitation of Liability: </strong>
            4UM, ITS AFFILIATES AND THEIR RESPECTIVE CONTROL PERSONS, MANAGERS,
            MEMBERS, STOCKHOLDERS, OFFICERS, EMPLOYEES AND AGENTS WILL NOT BE
            RESPONSIBLE FOR ANY LOSS OF BUSINESS OPPORTUNITIES, GOODWILL PROFITS,
            REVENUES, CORRUPTION OR LOSS OF DATA, CONSEQUENTIAL DAMAGES, OR
            PUNITIVE DAMAGES CAUSED BY:
          </p>
          <ul style={{listStyleType: 'decimal'}}>
            <li>ANY CONTENT FOUND WITHIN OUR OFFERING;</li>
            <li>The REMOVAL OR ABSENCE OF ANY CONTENT FROM OUR OFFERING;</li>
            <li>ANY DISTURBANCE OR STOPPAGE OF OUR OFFERING;</li>
            <li>ANY ERRORS IN OUR OFFERING;</li>
            <li>ANY INJURY OR DAMAGE CAUSE BY OUR OFFERING;</li>
            <li>ANY ILLICIT ACCESS TO OR US OF OUR OFFERING;</li>
          </ul>
          <p>
            UNDER THIS AGREEMENT, THE MAXIMUM AGGREGATE LIABILITY OF 4UM TO USERS
            SHALL NOT EXCEED $1,000.00. THIS LIMITATION OF LIABILITY APPLIES TO ANY
            ALLEGATION, REGARDLESS OF THE LEGAL BASIS FOR ITS ASSERTION
          </p>
          <p>
            <strong>No Waiver: </strong>
            If you fail to comply with this Agreement, any failure or delay in
            the exercise of any right, power, or privilege provided to 4um under
            this Agreement shall not of itself amount to any waiver thereof, and
            any individual or partial exercise of said rights, powers or privileges
            shall not represent any waiver for us to exercise that right, power,
            or privilege in the future.
          </p>
          <p>
            <strong>Severability & Enforceability: </strong>
            If any term or provision herein is invalid or unenforceable for any reason,
            such invalidity or unenforceability will not affect the validity or
            enforceability of other terms or provisions under this Agreement.
            Such invalid or unenforceable term or provision will be severed and the
            remain terms and provisions with remain in effect.
          </p>
          <p>
            <strong>Third Party Offerings: </strong>
            Our offering may contain links to third party links, services, urls,
            or websites which are not under the control or ownership of 4um.
            We assume no responsibility, legal or otherwise, for such links,
            services, urls, or websites. Any reference on the platform to any product,
            service, publication, institution, organization of any third-party entity
            or individual does not constitute or imply 4um's endorsement or recommendation.
          </p>
          <p>
            YOU AGREE THAT 4UM WILL NOT, UNDER ANY CIRCUMSTANCES, BE RESPONSIBLE OR LIABLE,
            DIRECTLY OR INDIRECTLY, FOR ANY GOODS, SERVICES, INFORMATION, RESOURCES AND/OR
            CONTENT AVAILABLE ON OR THROUGH ANY THIRD-PARTY SITES AND/OR THIRD-PARTY DEALINGS
            OR COMMUNICATIONS, OR FOR ANY HARM RELATED THERETO, OR FOR ANY DAMAGES OR LOSS
            CAUSED OR ALLEGED TO BE CAUSED BY OR IN CONNECTION WITH YOUR USE OR RELIANCE ON
            THE CONTENT OR BUSINESS PRACTICES OF ANY THIRD-PARTY.
          </p>
          <p>
            <strong>Third Party Rights: </strong>
            This Agreement does not provide rights to any third parties. Users may not
            transfer any of their rights or obligations hereunder without first obtaining
            our written consent. 4um’s rights and obligations hereunder may be transferred
            to our respective successors and permitted assigns (including in the instance
            of acquisition, merger, or sale).
          </p>
        </div>

        <div>
          <p style={{ textAlign: 'center' , color: colors.text.primaryTheme}}>
            <strong>CHANGES TO THESE TERMS</strong>&nbsp;
          </p>
        </div>
        <div>
          <p>
            4um reserves the right to modify our Offering, this Agreement, or our
            Policies to better serve our community and achieve the platform’s overarching
            purpose. If 4um makes changes to our Offering, our Terms of Use, or Policies
            we will notify you via the platform. If you do not consent to 4um’s Term of
            Use or Polices you must cease using the platform and delete your account.
            By continuing to use the platform following notice of changes, you acknowledge
            the revised Terms of Use and Policies and agree to be bound by them.
          </p>
        </div>

        <div>
          <p style={{ textAlign: 'center' , color: colors.text.primaryTheme}}>
            <strong>USER FEEDBACK</strong>&nbsp;
          </p>
        </div>
        <div>
          <p>
            Users are encouraged to submit Feedback, ideas, and suggestions
            (“<strong>Feedback</strong>”) to help us improve 4um’s Offering
            and make our community as safe as possible. By submitting any
            Feedback to 4um, users agree to the following terms:
          </p>
          <ul style={{listStyleType: 'decimal'}}>
            <li>
              Users grant 4um a perpetual, conveyable, free from royalty, global,
              nonexclusive, sublicensable license to disseminate, incorporate,
              modify, or utilize you Feedback in any form, now or in the future,
              without any obligation to you;
            </li>
            <li>
              Users understand and agree that you are not entitle to any acknowledgement,
              compensation, or royalties for Feedback you submit to 4um
            </li>
            <li>
              4um reserves the right to disregard your Feedback at its sole discretion and is
              under no obligation to consider, implement, or review any Feedback you submit
            </li>
            <li>
              4um is under no obligation to keep your Feedback Confidential.
              Any Feedback you provide is considered non-confidential
            </li>
            <li>
              Users represent and warrant that any Feedback they submit to 4um:
              (a) is their original work or that they have all the necessary legal
              authority and rights to submit the Feedback to 4um; and (b) does not
              infringe upon the intellectual property rights or any other legal
              rights of any third party; and
            </li>
            <li>
              Users waive any claims (including breach of contract, infringement,
              unfair competition, or other legal theories) against 4um and our
              affiliates regarding the use of such Feedback
            </li>
          </ul>
        </div>
        <div>
          <p>Account creation/responsibility for account and password security</p>
          <p>Ownership of the platform and related intellectual property</p>
          <p>DMCA notice</p>
          <p>Mobile application terms</p>
          <p>
            Statement that the platform is operated from the US and that it and its
            content may not be appropriate in other jurisdictions.
            User responsible for compliance.
          </p>
        </div>
      </div>

      <div style={{textAlign:'justify'}}>
        <p style={{ textAlign: 'center' , color: colors.text.primaryTheme}}>
          <strong>CONTACT US</strong>&nbsp;
        </p>
      </div>
      <div>
        <p>If Users have any comments, Feedback, or questions please contact us:</p>
        <ul style={{listStyle: 'none'}}>
          <li><strong>Via Email:</strong></li>
          <li><p style={{color: colors.text.primaryTheme}}><u>admin@4um.com</u></p></li>
        </ul>
        <br/>
        <ul style={{listStyle: 'none'}}>
          <li><strong>Via Mail:</strong></li>
          <li><p></p></li>
          <li><strong>4um LLC</strong></li>
          <li><strong>8 The Green, Suite 18572</strong></li>
          <li><strong>Dover, DE 19901</strong></li>
        </ul>
      </div>
      <br/>
      <div>
        <p style={{ fontStyle: 'italic'}}>
          Effective as of July 4, 2024
        </p>
      </div>
    </>
  );
};

export default TermsOfUse;
