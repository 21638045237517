export const getUtcTimeDifference = (
  pastDateInput: string
): {
  days: number;
  hours: number;
  minutes: number;
  seconds: number;
  milliSeconds: number;
  weeks: number;
  months: number;
  years: number;
} => {
  const pastDate = new Date(pastDateInput);
  const currentDate = new Date();
  let diffInMilliseconds = currentDate.getTime() - pastDate.getTime();
  let diffInSeconds = Math.floor(diffInMilliseconds / 1000);
  let diffInMinutes = Math.floor(diffInSeconds / 60);
  let diffInHours = Math.floor(diffInMinutes / 60);
  let diffInDays = Math.floor(diffInHours / 24);
  let diffInWeeks = Math.floor(diffInDays / 7);
  let diffInMonths = Math.floor(diffInDays / 30);
  let diffInYears = Math.floor(diffInDays / 365);

  return {
    days: diffInDays,
    hours: diffInHours % 24,
    minutes: diffInMinutes % 60,
    seconds: diffInSeconds % 60,
    milliSeconds: diffInMilliseconds,
    weeks: diffInWeeks,
    months: diffInMonths,
    years: diffInYears
  };
};

export const displayDurationInHrsAndMins = (createdAt: string) => {
  const { days, hours, minutes, seconds, milliSeconds, weeks, months, years } =
    getUtcTimeDifference(createdAt);

  let times = {
    time: milliSeconds,
    timeLabel: ''
  };
  if (years > 0) {
    times.timeLabel = `${years} year`;
  } else if (months > 0) {
    times.timeLabel = `${months} month`;
  } else if (weeks > 0) {
    times.timeLabel = `${weeks} week`;
  } else if (days > 0) {
    times.timeLabel = `${days} day`;
  } else if (hours > 0) {
    times.timeLabel = `${hours} hr`;
  } else if (minutes > 0) {
    times.timeLabel = `${minutes} min`;
  } else {
    times.timeLabel = `${seconds > 0 ? seconds : 0} sec`;
  }
  const count = Number(times.timeLabel.split(' ')[0]);
  times.timeLabel = `${times.timeLabel}${count > 1 ? 's' : ''} ago`;
  return times;
};

export const dateFormat = (createdAt: string) => {
  const date = new Date(createdAt).toLocaleDateString();
  return date;
};

export const timeLeftDuration = (timeInSeconds: number|undefined) => {
  if (!timeInSeconds || timeInSeconds < 0) {
    return 'Poll ended';
  } else {
    if (timeInSeconds < 60) {
      return timeInSeconds + ' seconds left';
    } else if (timeInSeconds/60 < 60) {
      return Math.round(timeInSeconds/60) + ' minutes left';
    } else if (timeInSeconds/(60 * 60) < 24) {
      return Math.round(timeInSeconds/(60 * 60)) + ' hours left';
    } else {
      return Math.round(timeInSeconds/(60 * 60 * 24)) + ' days left';
    }
  }
};