import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from 'store/hook';
import { clearGuestAction } from 'store/guestModeSlice';
import { IGuestModeOptions } from 'models/apiPaths';

export const convertWords = (text: string) => {
  const word = text
    .toLowerCase()
    .split(' ')
    .map((s) => s.charAt(0).toUpperCase())
    .join('');
  return word;
};

export const scrollToPageTop = (
  topPosition?: number,
  scrollBehavior?: ScrollBehavior
) => {
  window.scrollTo({
    top: topPosition ?? 0,
    behavior: scrollBehavior ?? 'smooth'
  });
};

export const getHeight = (id: string) => {
  const background = document.getElementById(id);
  const height = (background && Math.max(background.clientHeight)) || 0;
  return height;
};

export const stringToSpaceTrim = (text: string) => {
  return text.trim()?.length > 0;
};

export const blockedUser = (userDetails : any) => {
  const isBlocked = !userDetails?.blocked && !userDetails?.blockedByOther;
  return isBlocked;
};

export const GuestModeRedirection = (guestMode: IGuestModeOptions) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  if (guestMode?.guestMode) {
    if (guestMode?.action !== 'create-account' && guestMode?.action !== 'sign-in' && guestMode?.redirectTo) {
      navigate(guestMode?.redirectTo);
    } else {
      dispatch(clearGuestAction());
    }
  }
};
