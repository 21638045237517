import { colors } from 'styles/colors';

const PrivacyPolicy = () => {
    return (
            <div style={{textAlign:'justify', padding: '0 5px'}}>
                <p style={{ textAlign: 'center' , color: colors.text.primaryTheme}}>
                    <strong>Privacy Policy </strong>
                </p>
                <p style={{ fontStyle: 'italic'}}>
                    Effective Date: February 9, 2025
                </p>
                <p style={{ fontStyle: 'italic'}}>
                    Last Updated: February 9, 2025
                </p>
                <p>
                    4um values the privacy & security of everyone who is a part of our
                    community. We have instituted the following privacy & security measures
                    to create a privacy-centric and secure platform.
                </p>
                <br/>
                <div>
                    <strong style={{color: colors.text.primaryTheme}}>Privacy Policy</strong>
                </div>
                <p>
                    4um collects and utilizes the personal information of its users to
                    improve our platform and better serve our users. While 4um will never
                    sell your personal information, we may share your personal information
                    with third parties to analyzed data, improve user experience, and provide
                    targeted advertising. User can control certain aspects of the sharing of
                    their data through their privacy settings. The following covers the type
                    of user information 4um collects, how we use that information, to whom
                    and we share that information with, and how users can control the collection,
                    use, and sharing of that information through our privacy settings.
                    For more Information on <strong style={{ color: colors.text.primaryTheme }}>State Specific Policies including the California Consumer Privacy Act (CCPA).</strong>
                </p>
                <br/>
                <p style={{ color: colors.text.primaryTheme, fontStyle: 'italic' }}>
                    <strong>1. Collection of your Information</strong>
                </p>
                <p>
                    The specific types of information (“<strong style={{ color: colors.text.primaryTheme }}>Personal Information</strong>”)  we collect include:
                </p>
                <p>
                    <strong style={{color: colors.text.primaryTheme}}>-	Personal Information Collected Directly From Users: </strong>
                    Name, username, age
                    and date of birth, proof of identification, password, email, phone number,
                    location, address, language settings, uploaded images, media, registration information,
                    login information, personal profession, user settings and preferences,
                    security information, and other information provided by the user;
                </p>
                <p>
                    <strong style={{color: colors.text.primaryTheme}}>-	User Generated Content: </strong>
                    Uploads, videos, quotes, comments, streams, audio,
                    hashtags, reposts, sent messages, received messages, read messages, activity on
                    the platform, clipboard information, information from platform usage, information
                    from surveys, promotions, challenges, contests, marketing, events, information
                    collected from cookies, and associated metadata;
                </p>
                <p>
                    <strong style={{color: colors.text.primaryTheme}}>-	Device Specific Information: </strong>
                    Mobile carrier, GPS, WiFi, IP Address, time zone, and OS;
                </p>
                <p>
                    <strong style={{color: colors.text.primaryTheme}}>-	Correspondence: </strong>
                    Information from requests you send to our support teams;
                </p>
                <p>
                    <strong style={{color: colors.text.primaryTheme}}>-	Contacts: </strong>
                    Friends, followers, and phone contacts.
                </p>
                <br/>
                <div>
                  <p style={{ color: colors.text.primaryTheme, fontStyle: 'italic' }}>
                        <strong>2. Use of your Information</strong>
                    </p>
                </div>
                <p>
                    4um utilizes user data in the following ways:
                </p>
                <p>
                    <strong style={{color: colors.text.primaryTheme}}>-	Customization: </strong>
                    To customize specific Content, features, suggestions and services;
                </p>
                <p>
                    <strong style={{color: colors.text.primaryTheme}}>-	Advertising/Marketing: </strong>
                    While we will never sell your personal information,
                    we may share your personal information to automate, personalize,
                    and deliver targeted advertisements and marketing materials;
                </p>
                <p>
                    <strong style={{color: colors.text.primaryTheme}}>-	Business Transfers: </strong>
                    To allow a merger, acquisition, or reorganization of all or a portion of our assets.
                </p>
                <p>
                    <strong style={{color: colors.text.primaryTheme}}>-	Business Analytics: </strong>
                    To provide analytics to our advertisers and marketers to help them gauge
                    advertising efficacy and user response;
                </p>
                <p>
                    <strong style={{color: colors.text.primaryTheme}}>-	Product Improvement: </strong>
                    To personalize, develop, and improve products on our platform
                    including training algorithms and machine learning models;
                </p>
                <p>
                    <strong style={{color: colors.text.primaryTheme}}>-	Communication: </strong>
                    To contact, email, and provide notifications to our users;</p>
                <p>
                    <strong style={{color: colors.text.primaryTheme}}>-	Research & Innovation: </strong>
                    To better serve our communities, public interests, and social concerns;</p>
                <p>
                    <strong style={{color: colors.text.primaryTheme}}>-	Safety & Security: </strong>
                    To verify user accounts, identify user violations, detect fraud,
                    counteract dangerous conduct, and ensure the well-being and safety of users on our platform;
                </p>
                <p>
                    <strong style={{color: colors.text.primaryTheme}}>-	Law Enforcement: </strong>
                    To remain compliant with relevant laws and regulations;</p>
                <p><strong style={{color: colors.text.primaryTheme}}>-	Promotional Materials;</strong> and</p>
                <p><strong style={{color: colors.text.primaryTheme}}>-	Sales & Support.</strong></p>
                <br/>
                <div>
                  <p style={{ color: colors.text.primaryTheme, fontStyle: 'italic' }}>
                        <strong>3. Sharing of Information</strong>
                    </p>
                </div>
                <p>
                    4um shares user data with others in the following ways:
                </p>
                <p>
                    <strong style={{color: colors.text.primaryTheme}}>-	User Content Visible on your Profile: </strong>
                    With other users on the platform;
                </p>
                <p>
                    <strong style={{color: colors.text.primaryTheme}}>-	Service Providers & Partners: </strong>
                    With advertisers, business operations, marketers, partners, service providers, and vendors;
                </p>
                <p>
                    <strong style={{color: colors.text.primaryTheme}}>-	Law Enforcement: </strong>
                    With government, judicial, law enforcement, or regulatory bodies when requisite to
                    comply laws, legal requests, promote safety, regulations, or to address security matters;
                </p>
                <p>
                    <strong style={{color: colors.text.primaryTheme}}>-	Under Your Consent: </strong>
                    For reasons specified explicitly by your approval;</p>
                <p>
                    <strong style={{color: colors.text.primaryTheme}}>-	Upon Your Request: </strong>
                    Sharing your contact or profile information with third parties explicit upon your request;
                </p>
                <p>
                    <strong style={{color: colors.text.primaryTheme}}>-	Affiliates: </strong>
                    With related group companies or affiliated platforms; and</p>
                <p>
                    <strong style={{color: colors.text.primaryTheme}}>-	Cross-Border Information Sharing: </strong>
                    Internationally to operate our platform and improve services.
                </p>
                <br/>
                <div>
                    <p style={{ color: colors.text.primaryTheme, fontStyle: 'italic' }}>
                        <strong>4. Content Removal</strong>
                    </p>
                </div>
                <p>
                    Users who would like to remove their information from our platform may
                    delete their accounts in the settings tab. Further, parties may request
                    to have the unauthorized sharing of their private information removed
                    from our platform by sending a privacy complaint to&nbsp;
                    <u style={{ color: colors.text.primaryTheme }}>admin@4um.com</u>.
                </p>
                <br/>
                <p style={{ color: colors.text.primaryTheme }}>
                    <strong>Cookie Policy and Preferences Center</strong>
                </p>
                <p>
                    <strong style={{fontStyle: 'italic', color: colors.text.primaryTheme}}>Cookie Policy: </strong>
                    4um uses cookies to enhance user experience,
                    analyze site usage, and provide targeted advertising.
                    The types of cookies used on the platform include:
                </p>
                <p>
                    <strong style={{color: colors.text.primaryTheme}}>-	Essential Cookies: </strong>
                    Necessary for the operation of the platform, such as enabling secure logins
                    and remembering user preferences. Essential cookies ensure the basic
                    functionalities of the platform are available to all users.
                </p>
                <p>
                    <strong style={{color: colors.text.primaryTheme}}>-	Performance Cookies: </strong>
                    Used to collect information on how users interact with the platform and
                    to improve site functionality. Performance cookies help 4um understand
                    which pages are the most popular and how users navigate through the site,
                    allowing for continuous improvement of the user experience.
                </p>
                <p>
                    <strong style={{color: colors.text.primaryTheme}}>-	Functionality Cookies: </strong>
                    Implemented to provide a personalized user experience by storing user preferences
                    and remember past interactions on the platform. Functionality cookies enable
                    features such stored log-in details and customized Content.
                </p>
                <p>
                    <strong style={{color: colors.text.primaryTheme}}>-	Targeting Cookies: </strong>
                    Track user browsing habits to deliver relevant advertisements and measure
                    the effectiveness of ad campaigns. Targeting cookies ensure that users
                    see ads that are the most relevant to their interests and improve
                    both user experience and advertising efficacy.
                </p>
                <p>
                    <strong style={{fontStyle: 'italic', color: colors.text.primaryTheme}}>Preferences Center: </strong>
                    4um has implemented a Preferences Center that allows users to manage
                    their cookie settings. Users can change their cookie preferences at
                    any time through the Preferences Center. 4um will honor user preferences
                    and ensure that cookies are only used as permitted by the user.
                    We will regularly review and update its cookie policy to ensure
                    compliance with relevant laws and best practices.
                </p>
                <br/>
                <div>
                    <p style={{ color: colors.text.primaryTheme }}>
                        <strong>
                            State Specific Policies (including the California Consumer Privacy Act (CCPA),
                            Colorado Privacy Act (CPA), Connecticut Information Privacy Act (CTDPA),
                            Florida Digital Bill of Rights (FDBR), Oregon Consumer Privacy Act (OCPA),
                            Texas Data Privacy and Security Act (TDPSA), Utah Consumer Privacy Act (UCPA),
                            and Virginia Consumer Information Protection Act (VCDPA))
                        </strong>
                    </p>
                </div>
                <p>
                    4um is committed to complying with State Specific Policies including the
                    California Consumer Privacy Act (CCPA), Colorado Privacy Act (CPA),
                    Connecticut Information Privacy Act (CTDPA), Florida Digital Bill of Rights (FDBR),
                    Oregon Consumer Privacy Act (OCPA), Texas Data Privacy and Security Act (TDPSA),
                    Utah Consumer Privacy Act (UCPA), and Virginia Consumer Information Protection Act (VCDPA)
                    (collectively “<strong style={{ color: colors.text.primaryTheme }}>State Specific Policies</strong>”).
                    4um provides the following rights to users, including:
                </p>
                <p>
                    <strong style={{ color: colors.text.primaryTheme }}>Use of your Personal Information: </strong>
                    While 4um will never sell your personal information, we may share your personal information
                    to automate, personalize, and deliver targeted advertisements and marketing materials:
                </p>
                <p><strong style={{ color: colors.text.primaryTheme }}>-	Types User Data Collected: </strong>
                    Data collected from users includes the following categories:
                </p>
                <ul>
                    <li>Personal Information Collected Directly From Users;</li>
                    <li>User Generated Content;</li>
                    <li>Device Specific Information;</li>
                    <li>Correspondence; and</li>
                    <li>Contacts (see <strong style={{ color: colors.text.primaryTheme }}>1. Collection of your Personal Information</strong> above for further details).</li>
                </ul>
                <p>
                    <strong style={{ color: colors.text.primaryTheme }}>-	Data Minimization: </strong>
                    4um will only collect and retain user data necessary to automate, personalize,
                    and deliver targeted advertisements and marketing materials.
                </p>
                <p>
                    <strong style={{ color: colors.text.primaryTheme }}>-	Purpose Limitation: </strong>
                    User data collected and retained by 4um will be limited to the areas discussed above
                    (see <strong style={{ color: colors.text.primaryTheme }}>2. Use of your Information</strong>).
                    We will never repurpose user data for any reasons without first obtain user consent.
                </p>
                <p>
                    <strong style={{ color: colors.text.primaryTheme }}>-	Storage Limitation: </strong>
                    User data will only be retained as long as necessary to serve specific business purposes.
                    User data will not be held indefinitely. Once the appropriate business purpose
                    has been served, customer data will be deleted.
                </p>
                <p>
                    <strong style={{ color: colors.text.primaryTheme }}>-	Sensitive Personal Information: </strong>
                    While 4um may collect sensitive personal information including precise geolocation,
                    racial or ethnic origin, religious or philosophical beliefs, union membership,
                    internet data, we will never sell your personal information. We may share sensitive
                    personal information with third parties to automate, personalize, and deliver
                    targeted advertisements and marketing materials and to improve our community and your
                    personal experience on our platform. We may also analyze your sensitive personal
                    information to better understand societal trends/preferences and changes in demographics.
                    We will only retain your sensitive personal information as long as necessary to
                    conduct necessary business purposes. We will ensure that your sensitive personal
                    information is protected to the best of our ability by utilizing safety and cybersecurity protocols.
                </p>
                <p>
                    <strong style={{ color: colors.text.primaryTheme }}>-	Right to Know/Access: </strong>
                    Users can request that 4um disclose what personal information it collects, uses,
                    discloses, and sells. This includes providing a comprehensive report detailing:
                </p>
                <ul>
                    <li>Categories of personal information collected;</li>
                    <li>Purposes for which the information is used;</li>
                    <li>Sources from which the information is collected;</li>
                    <li>Third parties with whom the information is shared; and</li>
                </ul>
                <p>
                    <strong style={{ color: colors.text.primaryTheme }}>-	Right to Correct: </strong>
                    Users can request that 4um correct inaccuracies in the personal information
                    that it collects from them.
                </p>
                <p>
                    <strong style={{ color: colors.text.primaryTheme }}>-	Right to Delete: </strong>
                    Users can request the deletion of personal information that 4um has collected
                    from them. This includes all records held by 4um and any third-party service
                    providers. Upon request, 4um will review to see if any exemptions apply that
                    may prevent full deletion (including compliance with legal obligations,
                    violations of our Terms of Service or Polices, or detecting security incidents).
                    If any exemptions apply, the user will be notified that all or part of their
                    personal information cannot be deleted. If no exemptions apply, the user’s personal
                    information will be promptly deleted, and the user will be notified.
                </p>
                <p>
                    <strong style={{ color: colors.text.primaryTheme }}>-	Right to Opt-Out: </strong>
                    Users have the right to opt out of the selling of their personal information
                    or of targeted advertising based on their personal information. Upon request,
                    the opt-out request will be promptly processed, and the user will be notified.
                    Users will be provided instructions on how to re-opt-in should they chose to
                    do so in the future. 4um will honor user’s opt-out preferences across all
                    information processing activities.
                </p>
                <p>
                    <strong style={{ color: colors.text.primaryTheme }}>-	Right to Information Portability: </strong>
                    Users have the right to have their personal information conveyed in a portable,
                    commonly used, and machine-readable format.
                </p>
                <p>
                    <strong style={{ color: colors.text.primaryTheme }}>-	Non-Discrimination: </strong>
                    4um will not discriminate against users who exercise their State-Specific privacy rights.
                    This ensures that users will not face any adverse consequences, such as being denied
                    services or charged different prices, for exercising their privacy rights.
                </p>
                <p>
                    <strong style={{ color: colors.text.primaryTheme }}>-	Right to be Notified: </strong>
                    4um will inform users beforehand if the platform plans to use or share any sensitive
                    personal information (including social security number, financial information,
                    precise geolocation data, or genetic data) for purposes beyond what is strictly
                    need to deliver products or services on our platform.
                </p>
                <p>
                    <strong style={{ color: colors.text.primaryTheme }}>
                        -	Right to Limit Use, Sale, and Disclosure of Sensitive Personal Information:&nbsp;
                    </strong>
                    Users have the right to limit the use and disclosure of sensitive information
                    collected about them. Users may request that 4um only use their personal
                    information (including social security number, financial information,
                    precise geolocation data, or genetic data) for the purposes of providing
                    you with the services you have requested.
                </p>
                <br/>
                <div>
                  <p style={{ color: colors.text.primaryTheme }}>
                        <strong>Child Safety Standards Policy</strong>
                    </p>
                </div>
                <p>
                    <strong style={{ color: colors.text.primaryTheme, fontStyle: 'italic' }}>
                        Children’s Online Privacy Protection Rule (“COPPA”):&nbsp;
                    </strong>
                    Users must be 13 years of older to use 4um. Anyone under 18 using 4um
                    confirms that they have obtained permission from a parent or legal
                    guardian to do so. Parents or legal guardians of children under 18
                    who use our platform represent and warrant that they are bound by
                    this Agreement and the Policies governing use of the platform and
                    that they are responsible for their child’s actions on 4um.&nbsp;
                    <strong>
                        Any accounts identified as belonging to children under
                        the age of 13 will be removed from the platform along with
                        any uploaded content or personal information.
                    </strong>
                </p>
                <p>
                    <strong style={{ color: colors.text.primaryTheme, fontStyle: 'italic' }}>
                        Child Sexual Abuse and Exploitation (CSAE):&nbsp;
                    </strong>
                    4um strictly prohibits the production, advertisement, transportation,
                    distribution, receipt, sale, access with intent to view, and possession
                    of child sexual abuse material (CSAM) or child pornography.
                    In accordance with state, federal, and international laws pertinent
                    to child safety laws and regulations, after obtaining actual knowledge
                    of CSAM and violations of the platform’s CSAE Policies, 4um will:
                </p>
                <ul>
                    <li>Remove any CSAM related content;</li>
                    <li>
                        Suspend and/or permanently ban, pending administrative review,
                        any accounts uploading CSAM or engaging in the dissemination or
                        promotion of CSAM related content;
                    </li>
                    <li>
                        Report violations, pending administrative review, to local law
                        enforcement, the <u style={{ color: colors.text.primaryTheme }}>National Center for Missing and Exploited Children</u>,
                        and the <u style={{ color: colors.text.primaryTheme }}>relevant regional authority</u>;
                    </li>
                    <li>
                        And, pending administrative review, pursue criminal or civil action
                        against any offenders found guilty of uploading, dissemination,
                        or promoting CSAM related content.
                    </li>
                </ul>
                <p>
                    <strong style={{ color: colors.text.primaryTheme, fontStyle: 'italic' }}>
                        Reporting Violations of our Child Safety Standards Policy:&nbsp;
                    </strong>
                    If you believe that content or profiles on our platform are in violation
                    of our Child Safety Standards Policy, you may submit feedback, concerns,
                    or reports via the following <u style={{ color: colors.text.primaryTheme }}>webform</u>,
                    or by sending email or mail to our Information Privacy Team (see 
                    the <strong style={{ color: colors.text.primaryTheme }}>Privacy Requests </strong>
                    section below for further information on submitting privacy requests).
                </p>
                <br/>
                <div>
                  <p style={{ color: colors.text.primaryTheme }}>
                        <strong>Shine the Light Law</strong>
                    </p>
                </div>
                <p>
                    4um empowers its users by providing transparency on how we monetize your personal information.
                    Users may either (i) opt out of information sharing or (ii) request a detailed disclosure
                    on how your personal information is shared for direct marketing purposed
                    (see the <strong style={{ color: colors.text.primaryTheme }}>Privacy Requests </strong> section
                    below for further information on submitting privacy requests).
                </p>
                <br/>
                <div>
                  <p style={{ color: colors.text.primaryTheme }}>
                        <strong>Privacy Requests</strong>
                    </p>
                </div>
                <p>
                    To exercise the privacy rights guaranteed under State-Specific Policies,
                    4um has established the following detailed procedures:
                </p>
                <p>
                    <strong style={{ color: colors.text.primaryTheme }}>-	Submitting Requests: </strong>
                    Users can submit requests through email, mail, or the following&nbsp;
                    <u style={{color: colors.text.primaryTheme}}>webform</u>.&nbsp;
                    Users should include their name, username, address, phone number,
                    email, and request. 4um will respond to verifiable requests within
                    45 days, as required by law. Users may also designate an authorized
                    agent to submit request on their behalf. If users submit requests
                    through an authorized agent, we may require that you directly verify
                    your identity with 4um and confirm that you have provided the authorized
                    agent permission to submit request on your behalf.
                </p>
                <ul>
                    <li>
                        <strong style={{ color: colors.text.primaryTheme }}>Email: </strong>
                        Users can send requests to <u style={{ color: colors.text.primaryTheme }}>admin@4um.com</u>.
                    </li>
                    <li>
                        <strong style={{ color: colors.text.primaryTheme }}>Mail: </strong>
                        Written requests can be sent to 4um’s Information Privacy Team:
                    </li>
                    <li style={{listStyleType: 'none'}}>4um Data Privacy</li>
                    <li style={{listStyleType: 'none'}}>8 The Green, Suite A</li>
                    <li style={{listStyleType: 'none'}}>Dover, DE 19901</li>
                    <li>
                        <strong style={{ color: colors.text.primaryTheme }}>Webform: </strong>
                        Users can submit requests by filling out the following <u style={{ color: colors.text.primaryTheme }}>webform</u>
                    </li>
                </ul>
                <p>
                    <strong style={{ color: colors.text.primaryTheme }}>-	Verification: </strong>
                    To protect user privacy, 4um will verify the identity of the requester using the following methods:
                </p>
                <ul>
                    <li>
                        <strong style={{ color: colors.text.primaryTheme }}>Email Verification: </strong>
                        Sending a verification email to the registered email address of the user.
                    </li>
                    <li>
                        <strong style={{ color: colors.text.primaryTheme }}>Phone Verification: </strong>
                        Contacting the user via their registered phone number:
                    </li>
                    <li>
                        <strong style={{ color: colors.text.primaryTheme }}>Government-Issued ID: </strong>
                        In some cases, a copy of a government-issued ID may be requested to
                        confirm identity. This step ensures that personal information is only
                        disclosed to the rightful owner and prevents unauthorized access
                    </li>
                </ul>
                <br/>
                <div>
                  <p style={{ color: colors.text.primaryTheme }}>
                        <strong>Privacy Policy Updates</strong>
                    </p>
                </div>
                <p>
                    To stay current with evolving privacy laws, 4um will regularly review
                    and update its Privacy Policies and procedures. This includes:
                </p>
                <p>
                    <strong style={{ color: colors.text.primaryTheme }}>-	Regular Policy Updates: </strong>
                    Reviewing and updating privacy policies at least annually or as required by changes in law.
                </p>
            </div>
    );
};

export default PrivacyPolicy;
