import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isChatEnabled: true
};

const featureFlagSlice = createSlice({
  name: 'featureFlag',
  initialState,
  reducers: {
    setFeatureFlag: (state, action) => {
      state.isChatEnabled = true;
    }
  }
});

export const { setFeatureFlag } = featureFlagSlice.actions;

export default featureFlagSlice.reducer;
