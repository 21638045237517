import { Box } from '@mui/material';
import { Outlet } from 'react-router-dom';
import SideBar from './sidebar';
import Header from './header';
import { useCallback, useEffect } from 'react';
import { colors } from 'styles/colors';
import RightSidePanel from './rightSidePanel';
import { useAppDispatch, useAppSelector } from 'store/hook';
import { RootState } from 'store';
import { initializeSignalR } from 'services/SignalRService';
import { getFeatureFlag } from 'services/api';
import { setFeatureFlag } from 'store/featureFlagSlice';
import { clearGuestAction } from 'store/guestModeSlice';

const Layout = () => {
  const { isChatEnabled }: any = useAppSelector(
    (state: RootState) => state.featureFlag
  );
  const guestMode = useAppSelector((state: RootState) => state.guestMode);
  const dispatch = useAppDispatch();
  const { isLoggedIn, userProfile, authToken }: any = useAppSelector(
    (state: RootState) => state.userAuth
  );

  const fetchAndSetFeatureFlag = useCallback(async () => {
    try {
      if (authToken) {
        const response = await getFeatureFlag();
        dispatch(setFeatureFlag({ isChatEnabled: true }));

        if (response.chatEnabled && userProfile?.id) {
          dispatch(initializeSignalR(userProfile?.id));
        }
      }
    } catch (error: any) {
      console.error('Feature flag API call failed:', error.response?.data?.message || error.message);
    }
  }, [authToken, dispatch, userProfile?.id]);

  const checkAndClearGuestModeData = useCallback(async () => {
    const actionList = ['pollVote', 'votePost', 'sharePost', 'rePost'];
    if (guestMode?.action && !actionList.includes(guestMode?.action)) {
      dispatch(clearGuestAction());
    }
  }, [dispatch, guestMode?.action]);

  useEffect(() => {
    fetchAndSetFeatureFlag();
    checkAndClearGuestModeData();
  }, [fetchAndSetFeatureFlag, checkAndClearGuestModeData]);
  

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: { xs: 'column', sm: 'row' },
          background: colors.background.white,
          height: '100%',
          width: '100%',
          margin: '0 auto',
          gap: { xs: 4, sm: 0 }
        }}
        maxWidth={'xl'}
      >
        <Box
          sx={{
            width: { xs: '100%', sm: '20%', md: '25%' },
            height: { xs: '100%', sm: '100vh' },
            // overflow: { md: 'hidden' },
            position: { xs: 'relative', sm: 'sticky' },
            top: 0
          }}
        >
          <SideBar isLoggedIn={isLoggedIn}/>
        </Box>
        <Box
          flex={1}
          sx={{
            width: { xs: '100%', sm: '50%', md: '50%' },
            display: 'flex',
            flexDirection: 'column',
            borderLeft: `1px solid ${colors.border.primary}`,
            borderRight: `1px solid ${colors.border.primary}`
          }}
        >
          <Box
            sx={{
              borderBottom: `1px solid ${colors.border.primary}`,
              height: 70,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              px: 3,
              position: 'sticky',
              top: 0,
              zIndex: 999,
              background: colors.background.white
            }}
          >
            <Header />
          </Box>

          <Box
            id='scrollToElement'
            sx={{
              height: 'calc(100vh - 71px)',
              overflow: 'auto'
            }}
          >
            <Outlet />
          </Box>
        </Box>
        <Box
          sx={{
            width: { xs: '100%', sm: '30%', md: '25%' },
            height: '100vh',
            px: 2
          }}
        >
          <RightSidePanel isLoggedIn={isLoggedIn}/>
        </Box>
      </Box>
    </>
  );
};

export default Layout;
